import _ from 'lodash'
import { CardOptions } from 'components/common'
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import type { Item } from 'types/common'
import { DataTableProps } from '../../types'

const BodyActionsTemplate =
  (itemActions: DataTableProps['itemActions']) => (rowData: Item) => {
    return (
      !_.isEmpty(itemActions) && (
        <CardOptions
          {...itemActions}
          subject={rowData}
          className='actionMenu'
          placement={TOOLTIP_PLACEMENT.bottomRight}
        />
      )
    )
  }

export default BodyActionsTemplate
