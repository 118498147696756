import { SelectedItemsActions } from 'components/common/List/hooks/useSelectableItems'
import IconButton from 'components/common/IconButton'
import { stopEventDefaultAndPropagation } from 'helpers/utils'
import type { Item } from 'types/common'
import { SelectRowCheckBox } from '../../styles'

const BodySelectTemplate = (selectedItemsActions: SelectedItemsActions) => {
  return (rowData: Item & { disabled?: boolean }) => {
    const { id, disabled = false } = rowData
    return (
      <SelectRowCheckBox disabled={disabled}>
        <IconButton
          disabled={disabled}
          icon={
            selectedItemsActions.has(id)
              ? 'MdCheckBox'
              : 'MdCheckBoxOutlineBlank'
          }
          onClick={e => {
            selectedItemsActions.toggle(id)
            stopEventDefaultAndPropagation(e)
          }}
          size={24}
        />
      </SelectRowCheckBox>
    )
  }
}

export default BodySelectTemplate
