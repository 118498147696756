import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import MapGL, { Marker } from 'react-map-gl'
import MapboxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'
import _ from 'lodash'
import { round } from '@turf/helpers'
import { useAsync, useUpdateEffect } from 'react-use'

// constants
import {
  GEOMETRY_TYPES,
  INITIAL_MAP_STATE,
  MAP_STYLE_TYPES,
} from 'constants/map'

import { useBranding } from 'hooks'

import type { CallbackEvent } from 'react-map-gl/src/components/draggable-control'
import type { Point, Position } from 'geojson'

import Pin from './Pin'

import scss from './index.module.scss'

const calculateInitialMarker = (coordinates: Position) =>
  _.isEmpty(coordinates)
    ? {}
    : {
        latitude: coordinates[1],
        longitude: coordinates[0],
      }

const calculateInitialViewport = (coordinates: Position) =>
  _.isEmpty(coordinates)
    ? _.pick(INITIAL_MAP_STATE, ['longitude', 'latitude', 'zoom'])
    : {
        latitude: coordinates[1],
        longitude: coordinates[0],
        zoom: 16,
        bearing: 0,
        pitch: 0,
      }

type MapWithMarkerProps = {
  point: Partial<Point>
  onChange?: (v: Partial<Point>) => void
  readonly: boolean
}

const MapWithMarker = ({
  point,
  onChange,
  readonly = false,
}: MapWithMarkerProps): ReactElement => {
  const { coordinates = [] } = point || {}
  const [marker, setMarker] = useState(calculateInitialMarker(coordinates))

  const [viewport, setViewport] = useState(
    calculateInitialViewport(coordinates)
  )

  useUpdateEffect(() => {
    // In readonly mode, update internal state when the external value has changed
    if (readonly) {
      setMarker(calculateInitialMarker(coordinates))
      setViewport(calculateInitialViewport(coordinates))
    }
  }, [readonly, coordinates])

  const { mapboxAccessToken } = useBranding()

  const geocodingClient = MapboxGeocoding({ accessToken: mapboxAccessToken })

  const onMarkerDragEnd = useCallback((event: CallbackEvent) => {
    setMarker(oldMarker => {
      const { longitude: oldLongitude, latitude: oldLatitude } = oldMarker
      const [newLongitude, newLatitude] = event.lngLat

      if (oldLongitude === newLongitude && oldLatitude === newLatitude) {
        return oldMarker
      }

      return {
        longitude: event.lngLat[0],
        latitude: event.lngLat[1],
      }
    })
  }, [])

  useEffect(() => {
    if (_.isEmpty(marker) || !onChange) return

    onChange({
      coordinates: [marker.longitude, marker.latitude],
      type: GEOMETRY_TYPES.Point,
    })
  }, [marker, onChange])

  const state = useAsync(async () => {
    if (_.isEmpty(marker)) return undefined
    const response = await geocodingClient
      .reverseGeocode({
        query: [marker.longitude, marker.latitude],
        types: ['address'],
      })
      .send()

    return _.first(_.split(_.get(response, 'body.features[0].place_name'), ','))
  }, [marker])

  const { value: location } = state

  const roundedCoords = useMemo(() => {
    if (_.isEmpty(marker)) return []
    const precision = 6

    return [
      round(marker.latitude, precision),
      round(marker.longitude, precision),
    ]
  }, [marker])

  return (
    <div className={scss.container}>
      <div className={scss.mapContainer}>
        <MapGL
          {...viewport}
          width='100%'
          height='100%'
          mapStyle={MAP_STYLE_TYPES.satellite}
          onViewportChange={setViewport}
          mapboxApiAccessToken={mapboxAccessToken}
        >
          {!_.isEmpty(marker) && (
            <Marker
              longitude={marker.longitude}
              latitude={marker.latitude}
              offsetTop={-20}
              offsetLeft={-10}
              {...(!readonly && {
                draggable: true,
                onDragEnd: onMarkerDragEnd,
              })}
            >
              <Pin size={20} />
            </Marker>
          )}
        </MapGL>
      </div>
      {!readonly && !_.isEmpty(marker) && (
        <>
          <div
            className={`text-secondary smallText text-center ${scss.dragNote}`}
          >
            Drag the marker to adjust your location
          </div>
          <hr className='my-0' />
        </>
      )}
      {!_.isEmpty(roundedCoords) && (
        <div
          className={`d-flex justify-content-between smallText ${scss.note}`}
        >
          <div className='semiBold'>{location}</div>
          <div>{roundedCoords.join(', ')}</div>
        </div>
      )}
    </div>
  )
}

export default MapWithMarker
