import { useMemo, ReactElement } from 'react'
import styled from '@emotion/styled'

// types
import type { MultiSelectProp } from 'components/common/MultiSelect'
import type { FilterSpec } from 'types/filter'
import type { Option } from 'types/common'

// utils
import useFiltersDropdown from 'components/common/List/FilterBuilder/hooks/useFiltersDropdown'

// components
import { MultiSelect } from 'components/common'

import { Blanket, getMenuStyles } from '../FilterForm'
import FilterTrigger from '../FilterTrigger'
import { getCommonSelectProps, getFilterDropdownBadgeLabel } from '../utils'

const SelectWrapper = styled.div<{ isOpen?: boolean }>`
  ${({ isOpen }) => (isOpen ? getMenuStyles() : '')}
`

const FilterAsyncDropdown = ({
  value,
  options,
  onChange,
  title,
  isMulti,
  isClearable,
  formatOptionLabel,
  useOptionValueOnly,
  ...rest
}: FilterSpec &
  MultiSelectProp & {
    title: string
    value: Option | Option[]
  }): ReactElement => {
  const { isOpen, toggleOpen, ref } = useFiltersDropdown(value)

  const labels = useMemo(() => getFilterDropdownBadgeLabel(value), [value])

  return (
    <div className='d-flex align-items-center flex-wrap' ref={ref}>
      <div className='position-relative'>
        <FilterTrigger
          title={title}
          labels={labels}
          toggleDropdown={toggleOpen}
        />

        {isOpen && <Blanket onClick={toggleOpen} />}

        <SelectWrapper isOpen={isOpen}>
          <MultiSelect
            isAsync
            {...rest}
            {...getCommonSelectProps({
              value,
              isMulti,
              useOptionValueOnly,
              onChange,
              toggleOpen,
              formatOptionLabel,
            })}
            // We need to keep the component itself mounted to have 'cacheOptions' working,
            // so we control the dropdown visibility via 'menuIsOpen'
            menuIsOpen={isOpen}
            removeControl={!isOpen}
            cacheOptions
            defaultOptions
          />
        </SelectWrapper>
      </div>
    </div>
  )
}

export default FilterAsyncDropdown
