// libraries
import { FC, ReactElement, useMemo, useRef } from 'react'
import RTooltip from 'rc-tooltip'
import _ from 'lodash'
import { useClickAway } from 'react-use'

// constants
import { THEMES } from 'constants/colour'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import * as Icons from 'components/icons'
import Button, { BUTTON_VARIANTS } from 'components/common/Button'

// styles
import 'rc-tooltip/assets/bootstrap.css'
import './index.scss'
import scss from './index.module.scss'

import type {
  AdvanceTooltipProps,
  InfoTooltipProps,
  TooltipProps,
} from './types'

const destroyTooltipOnHide = { keepParent: false }

export const Tooltip: FC<TooltipProps> = ({
  theme = THEMES.dark,
  visible,
  children,
  placement = 'right',
  onVisibleChange = _.noop,
  trigger = ['hover'],
  ...rest
}): ReactElement => {
  // In case the tooltip is controlled by 'visible' prop, keep 'destroyTooltipOnHide' as 'false'
  // so these 2 props will not conflict => the app will not crash
  const tooltipProps = _.isUndefined(visible)
    ? { destroyTooltipOnHide }
    : { visible, destroyTooltipOnHide: false }

  return (
    <RTooltip
      placement={placement}
      onVisibleChange={onVisibleChange}
      trigger={trigger}
      {...rest}
      {...tooltipProps}
    >
      {children}
    </RTooltip>
  )
}

const { InfoIcon } = Icons

export const InfoTooltip: FC<InfoTooltipProps> = ({
  message,
  placement,
  theme = THEMES.dark,
  overlayStyle,
  iconClassName = 'ms-1',
  icon = 'InfoIcon',
}): ReactElement => {
  const Icon = _.get(Icons, icon, InfoIcon)

  return (
    <Tooltip
      placement={placement}
      overlayClassName={theme}
      overlay={
        <div className={scss.infoTooltipOverlay} style={overlayStyle}>
          {message}
        </div>
      }
    >
      <Icon
        className={`${iconClassName} ${scss.infoIcon}`}
        data-testid='tooltip-icon'
      />
    </Tooltip>
  )
}

export const AdvanceTooltip: FC<AdvanceTooltipProps> = ({
  icon,
  subTitle,
  title,
  theme = THEMES.dark,
  placement,
  visible,
  onVisibleChange,
  children,
  width,
}): ReactElement => {
  const Icon = _.get(Icons, icon as string)
  const ref = useRef<HTMLDivElement>(null)

  useClickAway(ref, () => onVisibleChange && onVisibleChange(false))

  const triggerProps = useMemo(() => {
    return _.isUndefined(visible) ? { trigger: ['hover'] } : { visible }
  }, [visible])

  return (
    <Tooltip
      placement={placement as keyof typeof TOOLTIP_PLACEMENT}
      {...triggerProps}
      overlayClassName={theme}
      overlayInnerStyle={{ width: `${width}px` }}
      overlay={
        <div
          className={scss.container}
          ref={ref}
          style={{ maxWidth: `${width}px` }}
        >
          {(icon || subTitle) && (
            <div className='d-flex flex-row align-items-center justify-content-between mb-1'>
              <div className='d-flex'>
                {icon && <Icon size={20} className='me-1' />}
                <div className={scss.subtitle}>{subTitle}</div>
              </div>
            </div>
          )}
          <div>
            {onVisibleChange && (
              <Button
                variant={BUTTON_VARIANTS.link}
                icon='MdOutlineClose'
                iconSize={20}
                className={scss.closeBtn}
                onClick={() => onVisibleChange(false)}
              />
            )}
          </div>
          <div className={scss.title}>{title}</div>
        </div>
      }
    >
      {children}
    </Tooltip>
  )
}
