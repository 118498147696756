import {
  useState,
  createContext,
  useContext,
  useCallback,
  ReactElement,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react'
import to from 'await-to-js'
import { useMount, useToggle } from 'react-use'
import _ from 'lodash'
import queryString from 'query-string'

// constants
import {
  APP_NAMES,
  AUTH_CHALLENGE_TYPES,
  AUTH_ERROR_CODES,
} from 'constants/common'
import { URLS } from 'constants/route'

// utils
import AuthService, {
  AuthenticationError,
  isServerRequestForPDFGenerating,
} from 'services/authentication'
import log, { reportMessage, reportErrors, reportException } from 'helpers/log'
import { showError } from 'helpers/message'
import { useStateValue } from 'contexts'
import { useFetchAppSupportData, useAuthCurrentUser } from 'contexts/hooks'
import { hasNoMfa } from 'helpers/user'
import {
  getItemsToLocalStorage,
  removeItemsFromLocalStorage,
} from 'helpers/storage'

// components
import { Loading } from 'components/common'
import EndUserConsentModal from 'components/common/Modal/EndUserConsentModal'
import { LoginSetupMFA } from 'components/login/LoginForm/SetupMFA'

import type { User, Credentials } from 'types/user'
import type { Option } from 'types/common'
import type { SeverityOption, AssigneeOption } from 'types/issue'
import {
  FETCH_SUPPORT_DATA_TYPES,
  type UseFetchAppSupportDataReturnType,
} from './hooks/useFetchAppSupportData'

export type SignOut = {
  global?: boolean
}

export type AuthenticationContextValue = {
  authenticateUser: (values: {
    username: string
    password: string
  }) => Promise<boolean>
  loading: boolean
  isUserSignedIn: boolean
  isAuthenticated: boolean
  signOut: ({ global }?: SignOut) => Promise<void>
  loginEvent?: string
  setLoginEvent: Dispatch<SetStateAction<string | undefined>>
  setMFAVerified: Dispatch<SetStateAction<boolean>>
  usersList: User[]
  isSuperAdminRole: boolean
  isGroupAdminRole: boolean
  issueAssigneesOptions: AssigneeOption[]
  issueSeverityOptions: SeverityOption[]
  userGroups: string[]
  userGroupsOptions: Option[]
  loginLocalUser: () => void
  isExternal?: boolean
} & ReturnType<typeof useAuthCurrentUser> &
  UseFetchAppSupportDataReturnType

export const AuthenticationContext = createContext<AuthenticationContextValue>(
  {} as AuthenticationContextValue
)

const GET_USER_API_ERROR = 'There is an error fetching the user information'

// https://github.com/aws-amplify/amplify-js/blob/40cc22f8b332e4748c85504ca8e2ac2713fd87d1/packages/core/src/Credentials.ts#L239
const CREDENTIALS_FOR_GUEST_ERROR =
  'cannot get guest credentials when mandatory signin enabled'

const localDebugPrefix = '[Info: Local User Auto Login]'

const signOutAuthenticatedUser = async ({ global = false }: SignOut) => {
  return global ? AuthService.globalSignOut() : AuthService.signOut()
}

export const AuthenticationProvider = ({
  children,
}: {
  children: ReactElement
}): ReactElement => {
  const {
    actions: { resetLocalState },
    state: {
      userState: { usersList },
    },
  } = useStateValue()

  const [isUserSignedIn, setUserSignedIn] = useToggle(false)

  const [isMFAVerified, setMFAVerified] = useToggle(false)

  const [isShowingMFASetup, toggleShowingMFASetup] = useToggle(false)

  const isAuthenticated = isUserSignedIn && isMFAVerified

  const [loading, setLoading] = useToggle(false)
  const [loginEvent, setLoginEvent] = useState<string | undefined>()
  const [isShowingEndUserConsent, toggleShowingEndUserConsent] = useToggle(true)

  const signOut = useCallback(
    async ({ global = false }: SignOut = {}) => {
      resetLocalState()
      await signOutAuthenticatedUser({ global })
      window.location.href = URLS.LOGIN
    },
    [resetLocalState]
  )

  const { appName, currentUser, setCurrentUser, currentUserRoles } =
    useAuthCurrentUser()

  const pickedSupportDataTypes = useMemo(() => {
    return _.get(
      {
        // Since now we're actively using Issues in the Methane app,
        // we need to fetch some support data to make this functionality work properly
        [APP_NAMES.methane]: [
          FETCH_SUPPORT_DATA_TYPES.user,
          FETCH_SUPPORT_DATA_TYPES.issue,
          FETCH_SUPPORT_DATA_TYPES.form,
        ],
        [APP_NAMES.studio]: undefined,
      },
      appName
    )
  }, [appName])

  const {
    fetchAppSupportData,
    fetchAppSupportDataWithConcentCheck,
    userConsents,
    setUserConsents,
    fetchIssueTaskDataCollectionFormMetadata,
    ...restFetchAppSupportDataState
  } = useFetchAppSupportData({ toggleShowingEndUserConsent, signOut })

  const getCurrentUserData = useCallback(async (): Promise<User> => {
    const [userErr, user] = await to(AuthService.getCurrentUser())
    if (userErr || !user) {
      throw new AuthenticationError(
        `${GET_USER_API_ERROR}. ${userErr?.message}`
      )
    }

    if (user.disabled) {
      signOut()
      throw new AuthenticationError('The user is disabled')
    }

    log.info('Get user information successfully')
    setCurrentUser(user)
    return user
  }, [setCurrentUser, signOut])

  const loginLocalUser = useCallback(async () => {
    setLoading(true)

    try {
      const [session, user] = await Promise.all([
        AuthService.getSession(),
        getCurrentUserData(),
      ])
      const { authenticated, preferredMFA } = session || {}

      if (!authenticated) {
        throw new AuthenticationError('Not authenticated user.')
      }

      // session.preferredMFA should be better here but it is returning wrong value
      const { mfaPreferred, hasLoginCredentials } = user
      if (!user?.username) {
        throw new AuthenticationError('The user is not valid')
      }
      log.debug(`loginLocalUser getCurrentUserData successfully`)
      setUserSignedIn(true)
      setMFAVerified(true)

      const { skipMFASetupForNow } = getItemsToLocalStorage([
        'skipMFASetupForNow',
      ])
      if (
        hasNoMfa(mfaPreferred) &&
        !preferredMFA &&
        hasLoginCredentials &&
        !skipMFASetupForNow
      ) {
        toggleShowingMFASetup(true)
      }

      await fetchAppSupportDataWithConcentCheck(user, pickedSupportDataTypes)
    } catch (error) {
      log.info(error)
    } finally {
      setLoading(false)
    }
  }, [
    fetchAppSupportDataWithConcentCheck,
    getCurrentUserData,
    pickedSupportDataTypes,
    setLoading,
    setMFAVerified,
    setUserSignedIn,
    toggleShowingMFASetup,
  ])

  const loginFederatedUser = useCallback(async () => {
    const { location } = window

    const { access_token: cognitoAccessToken, id_token: cognitoIdToken } =
      queryString.parse(location.hash)
    if (!cognitoIdToken || !cognitoAccessToken) return

    log.info('loginFederatedUser', { cognitoAccessToken, cognitoIdToken })
    await AuthService.federatedSignIn({
      cognitoAccessToken: cognitoAccessToken as string,
      cognitoIdToken: cognitoIdToken as string,
    })

    window.location.href = location.pathname
  }, [])

  const autoAuthenticateUser = useCallback(async () => {
    try {
      await loginFederatedUser()
      await loginLocalUser()
    } catch (userErr) {
      const message = (
        _.isObject(userErr) ? (userErr as Error).message : userErr
      ) as string
      if (
        ![CREDENTIALS_FOR_GUEST_ERROR, GET_USER_API_ERROR].includes(message)
      ) {
        showError(message)
        reportException(userErr as Error)
      }
      log.warn(`${localDebugPrefix} failed: ${message}`)
      window.location.href = URLS.LOGIN
    } finally {
      setLoading(false)
    }
  }, [loginFederatedUser, loginLocalUser, setLoading])

  const authenticateUser = useCallback(
    async (values: Credentials) => {
      try {
        const { challengeName, username } =
          (await AuthService.signIn(values)) || {}
        if (!username) {
          log.debug('Sign in with credentials failed')
          return false
        }

        if (challengeName) {
          log.debug(`Found challenge name: ${challengeName} for ${username}`)
        } else {
          log.debug('Sign in with credentials successfully')
        }

        removeItemsFromLocalStorage(['skipMFASetupForNow'])
        setLoginEvent(challengeName)
        setMFAVerified(
          !_.includes(
            [
              AUTH_CHALLENGE_TYPES.SMS_MFA,
              AUTH_CHALLENGE_TYPES.SOFTWARE_TOKEN_MFA,
            ],
            challengeName
          )
        )
        if (!challengeName) {
          await loginLocalUser()
        }
        return true
      } catch (error) {
        const ERROR_PREFIX = `[Error: AuthenticateUser] Username: ${values.username}.`
        const { code, message } = error as { code: string; message: string }
        const throwErrorMessage = message
        if (code === AUTH_ERROR_CODES.USER_NOT_CONFIRMED) {
          reportMessage(`${ERROR_PREFIX}${throwErrorMessage}`)
          throw new AuthenticationError(throwErrorMessage)
        } else if (code === AUTH_ERROR_CODES.PASSWORD_RESET_REQUIRED) {
          log.debug('the user requires a new password')
          setLoginEvent(AUTH_CHALLENGE_TYPES.RESET_PASSWORD)
        } else if (code === AUTH_ERROR_CODES.DEVICE_NOT_EXIST) {
          removeItemsFromLocalStorage(
            AuthService.deviceInfoKeys,
            AuthService.getLocalStorageKeyPrefix(values.username)
          )
          await authenticateUser(values)
        } else {
          reportErrors(
            `${ERROR_PREFIX} Sign in failed due to ${throwErrorMessage}`,
            {
              error,
              username: values.username,
              code,
            }
          )
          throw new AuthenticationError(throwErrorMessage)
        }
        return false
      } finally {
        setLoading(false)
      }
    },
    [loginLocalUser, setLoading, setMFAVerified]
  )

  useMount(async () => {
    if (isServerRequestForPDFGenerating()) {
      await fetchIssueTaskDataCollectionFormMetadata(false)
      return
    }

    const autoLogin = async () => {
      log.debug(`${localDebugPrefix} Start`)
      await autoAuthenticateUser()
    }

    autoLogin()
  })

  const onAllConsentsConfirmed = useCallback(async () => {
    setUserConsents([])
    log.info(
      'The current user confirmed all consents, fetching app support data'
    )
    setLoading(true)
    await getCurrentUserData()
    await fetchAppSupportData(pickedSupportDataTypes)
    setLoading(false)
  }, [
    setUserConsents,
    setLoading,
    getCurrentUserData,
    fetchAppSupportData,
    pickedSupportDataTypes,
  ])

  const renderChildren = () => {
    if (isShowingMFASetup) {
      return (
        <LoginSetupMFA
          onSuccess={() => toggleShowingMFASetup(false)}
          nextStepText='Sign In'
        />
      )
    }

    return _.isEmpty(userConsents) ? (
      children
    ) : (
      <EndUserConsentModal
        onDisagree={signOut}
        userConsents={userConsents}
        isShowing={isShowingEndUserConsent}
        hide={toggleShowingEndUserConsent}
        onAllConsentsConfirmed={onAllConsentsConfirmed}
      />
    )
  }

  return (
    <AuthenticationContext.Provider
      value={{
        authenticateUser,
        ...currentUserRoles,
        ...restFetchAppSupportDataState,
        fetchIssueTaskDataCollectionFormMetadata,
        currentUser,
        setCurrentUser,
        appName,
        isUserSignedIn,
        setMFAVerified,
        isAuthenticated,
        signOut,
        loginEvent,
        setLoginEvent,
        loading,
        usersList,
        loginLocalUser,
      }}
    >
      {loading ? <Loading /> : renderChildren()}
    </AuthenticationContext.Provider>
  )
}

export const useAuthStateValue = (): AuthenticationContextValue =>
  useContext(AuthenticationContext)
