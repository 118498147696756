import _ from 'lodash'
import { useCallback } from 'react'

// utils
import { useRecoilLoadableResult } from 'hooks'
import { assetSitesOptionsState } from 'app/MissionControlMethaneSolution/recoilStore/assetTypeStore'
import {
  getAsyncPaginateNewOptions,
  loadAsyncPaginateSelectOptions,
} from 'components/common/MultiSelect/utils'

// components
import FilterAsyncDropdown from 'components/common/List/FilterBuilder/FilterAsyncDropdown'

import type { Options } from 'types/common'

type AdditionalType = {
  page: number
}

const AssetSitesFilterAsyncDropdown = ({
  value: selectedAssetOptions,
  ...rest
}: {
  value: Options
  title: string
}) => {
  const { data: assetSiteOptions, loading: isAssetSitesListLoading } =
    useRecoilLoadableResult(assetSitesOptionsState)

  const loadAssetSiteOptions = useCallback(
    (search: string, _loadedOptions: Options, additional: AdditionalType) => {
      const { page = 1 } = additional ?? {}

      const { options, hasMore } = loadAsyncPaginateSelectOptions({
        page,
        search,
        options: assetSiteOptions as Options,
      })

      const newOptions = getAsyncPaginateNewOptions({
        page,
        options,
        selectedOptions: selectedAssetOptions,
      })

      return {
        options: newOptions,
        hasMore,
        additional: {
          page: page + 1,
        },
      }
    },
    [assetSiteOptions, selectedAssetOptions]
  )

  return (
    <FilterAsyncDropdown
      {..._.omit(rest, ['filters'])}
      isMulti
      loadOptions={loadAssetSiteOptions}
      isAsyncPaginate
      isAsync={false}
      value={selectedAssetOptions}
      isLoading={isAssetSitesListLoading}
    />
  )
}

export default AssetSitesFilterAsyncDropdown
