import { ReactElement, useMemo } from 'react'
import _ from 'lodash'

// components
import { Button } from 'components/common'
import Dropdown, { type DropdownProps } from 'components/common/Dropdown'

// constants
import {
  BUTTON_ICON_POSITIONS,
  BUTTON_VARIANTS,
} from 'components/common/Button'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

import type { ColumnsWithGroupable } from 'components/common/DataTable/useDataTableColumns'

import { TABLE_GROUP_BY_OPTION } from '../..'

const GroupByDropdown = ({
  btnClassName = 'text-secondary p-2',
  tableGroupedBy,
  tableGroupableColumns,
  onChange,
}: {
  btnClassName?: string
  tableGroupedBy?: string
  tableGroupableColumns: ColumnsWithGroupable
  onChange: (v: string) => void
}): ReactElement => {
  const groupableColumnsOptions = useMemo(
    () =>
      _.map(tableGroupableColumns, ({ key, header, field, groupByField }) => ({
        label: key ?? header,
        value: groupByField ?? field,
      })),
    [tableGroupableColumns]
  )

  return (
    <div className='d-flex align-items-enter me-2'>
      <Dropdown
        selectedValue={tableGroupedBy}
        toggleComponent={() => (
          <Button
            className={btnClassName}
            variant={BUTTON_VARIANTS.link}
            icon='TableGroupBy'
            iconPosition={BUTTON_ICON_POSITIONS.left}
          >
            <>
              Group by{' '}
              {_.find(groupableColumnsOptions, { value: tableGroupedBy })
                ?.label || ''}
            </>
          </Button>
        )}
        placement={TOOLTIP_PLACEMENT.bottom}
        options={
          (tableGroupedBy
            ? [TABLE_GROUP_BY_OPTION, ...groupableColumnsOptions]
            : groupableColumnsOptions) as DropdownProps['options']
        }
        onChange={onChange as DropdownProps['onChange']}
      />
    </div>
  )
}

export default GroupByDropdown
