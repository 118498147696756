import _ from 'lodash'
import type { Options } from 'types/common'

/**
 * Only load a portion of the options to improve performance.
 */
export const loadAsyncPaginateSelectOptions = ({
  options,
  search,
  page = 1,
  optionsPerPage = 5,
}: {
  options: Options
  page?: number
  search?: string
  optionsPerPage?: number
}) => {
  let filteredOptions: Options
  if (search) {
    const searchLower = search.toLowerCase()
    filteredOptions = _.filter(options, ({ label }) =>
      label.toLowerCase().includes(searchLower)
    )
  } else {
    filteredOptions = options
  }

  const hasMore = Math.ceil(filteredOptions.length / optionsPerPage) > page

  const slicedOptions = _.slice(
    filteredOptions,
    (page - 1) * optionsPerPage,
    page * optionsPerPage
  )

  return {
    options: slicedOptions,
    hasMore,
  }
}

/**
 *  In case the preselected options are not included in the paginated options,
 *  and remove the duplicated options
 */
export const getAsyncPaginateNewOptions = ({
  page,
  options,
  selectedOptions,
}: {
  page: number
  options: Options
  selectedOptions: Options
}) => {
  if (_.isEmpty(_.compact(selectedOptions))) return options

  return page === 1
    ? _([...selectedOptions, ...options])
        .compact()
        .uniqBy('value')
        .value()
    : _.differenceBy(options, selectedOptions, 'value')
}
