import { FC, ReactElement } from 'react'
import _ from 'lodash'

// constants
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import {
  BUTTON_ICON_POSITIONS,
  BUTTON_VARIANTS,
} from 'components/common/Button'
import type { Value } from 'types/common'
import type { FilterSpec, Filters } from 'types/filter'

// components
import FilterDropdown from 'components/common/List/FilterBuilder/FilterDropdown'
import { Button, Dropdown } from 'components/common'

import scss from '../../index.module.scss'

interface DisplayButtonWrapperProps {
  filterSpec: FilterSpec
  value: Value
}

interface ButtonProps {
  onClick?: () => void
  className?: string
}

const DisplayButtonWrapper =
  ({ filterSpec, value }: DisplayButtonWrapperProps) =>
  ({ onClick, className }: ButtonProps) => {
    const { icon, label, options, isMulti, defaultLabel } = filterSpec

    const newLabel =
      options && !isMulti
        ? _.find(options, { value })?.label ?? defaultLabel
        : label

    return (
      <Button
        icon={icon}
        variant={BUTTON_VARIANTS.link}
        className={className}
        onClick={onClick}
        iconPosition={BUTTON_ICON_POSITIONS.left}
      >
        {newLabel}
      </Button>
    )
  }

interface QuickFilterProps {
  filterSpec: FilterSpec
  value: Value
  onChange: (value: Value) => void
}

const QuickFilter: FC<QuickFilterProps> = ({ filterSpec, value, onChange }) => {
  const { key, type, label, options, isMulti } = filterSpec
  const DisplayButton = DisplayButtonWrapper({ filterSpec, value })

  const renderFilter = () => {
    switch (type) {
      case PROPERTY_VARIABLE_TYPES.boolean:
        return (
          <DisplayButton
            className={value ? '' : scss.inactiveButton}
            onClick={() => onChange(!value)}
          />
        )
      case undefined:
        if (options) {
          if (isMulti) {
            return (
              <FilterDropdown
                key={key}
                options={options}
                title={label || key}
                value={value}
                useOptionValueOnly
                target={DisplayButton}
                initialIsDropdownOpen={false}
                onChange={onChange}
              />
            )
          }
          return (
            <Dropdown
              onChange={onChange}
              placement={TOOLTIP_PLACEMENT.bottom}
              toggleComponent={DisplayButton}
              options={options}
            />
          )
        }
        return null
      default:
        return null
    }
  }

  return renderFilter()
}
const QuickFilters = ({
  quickFilterSpecs,
  filterValues,
  onFiltersChange,
}: {
  quickFilterSpecs: FilterSpec[]
  filterValues: Filters
  onFiltersChange: (v: Filters) => void
}): ReactElement => {
  return (
    <>
      {_.map(quickFilterSpecs, filterSpec => {
        const { key } = filterSpec
        return (
          <div key={key} className={scss.toolboxItem}>
            <QuickFilter
              filterSpec={filterSpec}
              value={filterValues[key]}
              onChange={(newValue: Value | Value[]) => {
                onFiltersChange({ ...filterValues, [key]: newValue })
              }}
            />
          </div>
        )
      })}
    </>
  )
}

export default QuickFilters
