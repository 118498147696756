// libraries
import _ from 'lodash'
import type { JSONSchema7, JSONSchema7Type } from 'json-schema'

// constants
import {
  DEFAULT_ASSET_LAYER_TYPE,
  DEFAULT_ASSET_PROFILE_VIEW_CONFIGS_ID,
} from 'constants/assets'
import { PROPERTY_VARIABLE_FORMATS } from 'constants/filter'
import { DEFAULT_MAP_LAYER_NAME } from 'constants/map'
import { JSON_DATA_TYPES } from 'constants/formBuilder'

// utils
import { getDeckLayerClass } from 'components/map/layers/deckLayers'
import {
  getInvisibleProperties,
  getAllProfileProperties,
} from 'helpers/layerProfile'
import {
  convertArrayToObject,
  capitalizeFirstLetter,
  sanitizeString,
} from 'helpers/utils'

import type {
  AssetProfile,
  ViewConfiguration,
  AssetProfileViewConfigs,
  AssetProfileMapConfig,
} from 'types/asset'
import type { MapLayer, MapLayerData } from 'types/map'
import type {
  Payload,
  InternalPropertiesMetadata,
  PropertyMetadata,
  PropertiesMetadata,
} from 'types/common'
import { LAYER_VIS_CONFIG_KEYS } from 'components/map/layers/deckLayers/layerFactory'
import type { Timezone } from 'types/datetime'

export const getAssetProfileData = (
  assetsData: Payload,
  profileId: string
): MapLayerData => {
  return _.get(assetsData, [profileId, 'data'], [])
}

const getDefaultAssetViewConfig = (
  viewConfigurations: ViewConfiguration[] | undefined
) => {
  return _.get(
    _.keyBy(viewConfigurations, 'id'),
    DEFAULT_ASSET_PROFILE_VIEW_CONFIGS_ID,
    {}
  ) as ViewConfiguration
}

export const getAssetViewConfigs = (
  viewConfigurations: ViewConfiguration[] | undefined
): {
  profileConfigs: AssetProfileViewConfigs
  mapConfigs: MapLayer
} => {
  const { layouts, map } = getDefaultAssetViewConfig(viewConfigurations)
  const profileConfigs = _.keyBy(layouts, 'mediaType')
  return {
    profileConfigs,
    mapConfigs: map || {},
  }
}

export const generateAssetDeckLayer = ({
  mapLayer,
  layerData,
  profileHandler,
  timezone,
  currentZoom,
}: {
  mapLayer: MapLayer
  layerData: MapLayerData
  profileHandler: () => void
  timezone: Timezone
  currentZoom: number
}): [] => {
  const newLayer = getDeckLayerClass({ mapLayer, currentZoom })
  return newLayer.renderLayer({
    layerData,
    timezone,
    profileHandler,
  })
}

export const getAssetLayer = (
  layer: MapLayer,
  mapConfigs: AssetProfileMapConfig
): MapLayer => {
  const { style, type } = mapConfigs

  const newStyle = style?.[type]
    ? {
        ...layer.style,
        [type]: {
          ...style[type],
          [LAYER_VIS_CONFIG_KEYS.enableClustering]: _.get(
            layer,
            ['style', type, LAYER_VIS_CONFIG_KEYS.enableClustering],
            style[type][LAYER_VIS_CONFIG_KEYS.enableClustering]
          ),
        },
      }
    : layer.style

  return {
    ...layer,
    style: newStyle,
    type: mapConfigs.type || DEFAULT_ASSET_LAYER_TYPE,
  }
}

export const getAssetLayerConfig = (
  layer: MapLayer,
  assetProfiles: AssetProfile[]
): MapLayer => {
  const { profile: { assetProfileId } = {} } = layer

  const viewConfigurations = _.get(
    _.find(assetProfiles, { value: assetProfileId }),
    'viewConfigurations'
  )

  const { mapConfigs } = getAssetViewConfigs(viewConfigurations)

  return getAssetLayer(layer, mapConfigs)
}

export const getAssetProfilePropertiesList = ({
  propertiesMetadata,
  identifier,
  properties = [],
  onlyArrayProperties,
}: {
  propertiesMetadata: PropertiesMetadata
  identifier: string
  properties?: string[]
  onlyArrayProperties?: boolean
}): PropertyMetadata[] => {
  const propertyOptionsObj = (convertArrayToObject(
    propertiesMetadata,
    identifier
  ) || {}) as {
    [key: string]: {
      name: string
    }
  }

  const visibleProperties = _(properties)
    .filter(property => !!propertyOptionsObj[property])
    .map(property => ({ ...propertyOptionsObj[property], isVisible: true }))
    .value()

  const invisibleProperties = getInvisibleProperties({
    propertyOptionsObj,
    sortedPickedProperties: properties,
  })

  const processedProperties = getAllProfileProperties(
    visibleProperties,
    invisibleProperties
  )

  const filteredProperties = onlyArrayProperties
    ? processedProperties.filter(
        ({ type, format, terms }) =>
          // Using 'terms' to omit radio, checkboxes and multiselect
          type === JSON_DATA_TYPES.array && format !== 'image' && !terms
      )
    : processedProperties

  return filteredProperties
}

export const getImagesProperties = ({
  propertiesMetadata,
}: {
  propertiesMetadata?: PropertiesMetadata
}): PropertiesMetadata =>
  _.filter(
    propertiesMetadata,
    ({ format }) => format === PROPERTY_VARIABLE_FORMATS.image
  )

export const getPropertyDisplayNameFromMetadata = ({
  propertiesMetadataKeyByName,
  propertyName,
}: {
  propertiesMetadataKeyByName: InternalPropertiesMetadata
  propertyName: string
}): string => {
  const { displayName } = _.get(propertiesMetadataKeyByName, propertyName) || {}
  return `${sanitizeString(displayName) || capitalizeFirstLetter(propertyName)}`
}

const getAssetLayerDefaultName = (assetProfileId?: string): string =>
  `${_.upperFirst(assetProfileId)} Asset`

export const getAssetLayerName = ({
  name,
  assetsProfilesOptions,
  assetProfileId,
  assetProfileLabel,
  newAssetProfileId,
}: {
  name: string
  assetsProfilesOptions: (
    | { value: string; label: string }
    | { value: string; label: null }
  )[]
  assetProfileId?: string
  assetProfileLabel: string | null
  newAssetProfileId: string
}): string => {
  return name === DEFAULT_MAP_LAYER_NAME ||
    name === getAssetLayerDefaultName(assetProfileId) ||
    _.find(assetsProfilesOptions, { label: name })
    ? assetProfileLabel || getAssetLayerDefaultName(newAssetProfileId)
    : name
}

/** If:
 * - data comes from jsonForm
 * - it's a dropdown field
 * - and there is a schema available for that field
 * then:
 * - display the value's label instead of the raw value
 * Example: process_gas => Process Gas
 */
export const getFieldValue = ({
  termsDisplayName,
  value,
  fieldSchema,
}: {
  termsDisplayName?: string
  value?: unknown
  fieldSchema?: JSONSchema7 & { enumNames?: string[] }
}) => {
  const fieldValue = termsDisplayName ?? value
  const { enum: availableEnumValues, enumNames } = fieldSchema ?? {}

  if (!availableEnumValues || !enumNames) return fieldValue

  const fieldIndex = availableEnumValues.indexOf(fieldValue as JSONSchema7Type)
  return fieldIndex !== -1 ? enumNames[fieldIndex] : fieldValue
}
