// libraries
import { useCallback, useState } from 'react'

import type { DetectionData } from 'app/MissionControlMethaneSolution/types/detection'

const useDetectionSelect = ({
  detection,
  routeUrl,
}: {
  detection?: DetectionData
  routeUrl?: string
}): [
  Partial<DetectionData> | undefined,
  (v?: Partial<DetectionData>) => void
] => {
  const [selectedDetection, setSelectedDetection] = useState<
    Partial<DetectionData> | undefined
  >(detection)

  const onDetectionSelected = useCallback(
    (newSelectedDetection?: Partial<DetectionData>) => {
      setSelectedDetection(newSelectedDetection)

      if (routeUrl) {
        window.history.replaceState(
          undefined,
          '',
          `${process.env.PUBLIC_URL}${routeUrl}/${
            newSelectedDetection?.id ?? ''
          }`
        )
      }
    },
    [routeUrl]
  )

  return [selectedDetection, onDetectionSelected]
}

export default useDetectionSelect
