// libraries
import _ from 'lodash'
import { atom, selector } from 'recoil'

// constants
import {
  WORKFLOW_WIDGET_OPEN_ISSUE_FORM_REFERENCE,
  WORKFLOW_WIDGET_DATA_COLLECTION_TYPES,
} from 'constants/workflow'
import { ISSUE_TASK_DATA_COLLECTION_FORM_TYPES } from 'constants/issue'

// utils
import { getSpecificationParametersFromXForm } from 'components/issue/IssueFormEditModal/utils'
import { getIssueSubjectAssetFilters } from 'helpers/issue'

import type {
  Option,
  Options,
  SpecificationParameters,
  SpecificationParameterValue,
} from 'types/common'
import type {
  IssueFormOptions,
  DataCollectionForm,
  IssueTaskDataCollectionFormType,
  IssueDataCollectionFormReferences,
} from 'types/issue'
import type { Owner } from 'types/entity'
import type { UtcISOString } from 'types/datetime'
import type { AssetProfileId } from 'types/asset'

export const issueDataCollectionRelatedState = atom({
  key: 'issueDataCollectionRelatedState',
  default: {} as IssueDataCollectionFormReferences,
})

export type IssueTaskDataCollectionFormsOption = Option & {
  owner: Owner
  value: string
  label: string
  description: string
  type: keyof typeof WORKFLOW_WIDGET_OPEN_ISSUE_FORM_REFERENCE
  inputType: typeof WORKFLOW_WIDGET_DATA_COLLECTION_TYPES.TASK
  assetFilters: Options
  formType: IssueTaskDataCollectionFormType
  modifiedAt: UtcISOString
  isActive: boolean
  subjectAssetProfile: AssetProfileId
  subTasksStaticFormReferences?: string[]
}

export const issueTaskDataCollectionFormsOptionsState = selector({
  key: 'issueTaskDataCollectionFormsOptionsState',
  get: ({ get }): IssueTaskDataCollectionFormsOption[] => {
    const { issueTask } = get(issueDataCollectionRelatedState) || {}

    return _(issueTask?.dataCollectionForms)
      .filter('isTaskForm')
      .map(
        ({
          id: formId,
          title,
          subjectRules,
          formType,
          jsonFormBody,
          description,
          isActive,
          ...rest
        }: DataCollectionForm) => {
          const isJsonForm =
            formType === ISSUE_TASK_DATA_COLLECTION_FORM_TYPES.JSON_FORM

          const { schema } = isJsonForm ? jsonFormBody || {} : {}
          const {
            title: jsonFormTitle = '',
            description: jsonFormDescription = '',
          } = schema || {}

          return {
            ...rest,
            formType,
            value: formId,
            label: title || jsonFormTitle,
            description: description || jsonFormDescription,
            type: WORKFLOW_WIDGET_OPEN_ISSUE_FORM_REFERENCE.taskDataCollectionFormId,
            inputType: WORKFLOW_WIDGET_DATA_COLLECTION_TYPES.TASK,
            ...getIssueSubjectAssetFilters(subjectRules),
            // We could filter inactive forms from this list, but it seems like
            // in some cases we need to show an already selected option after a form was deactivated
            isActive,
            labelExtras: {
              ...(!isActive && { sideComponent: 'Inactive' }),
            },
          } as IssueTaskDataCollectionFormsOption
        }
      )
      .sortBy('label')
      .value()
  },
})

export const issueTaskFormOptionsState = selector({
  key: 'issueTaskFormOptionsState',
  get: ({ get }) => {
    const { issueMultitask } = get(issueDataCollectionRelatedState) || {}

    const formReferenceOptions =
      get(issueTaskDataCollectionFormsOptionsState) || {}

    const issueMultitaskOptions = _.map(
      issueMultitask?.procedures,
      ({
        id: procedureId,
        title,
        subjectRules,
        subTasksStaticFormReferences,
      }) => {
        return {
          value: procedureId,
          label: title,
          type: WORKFLOW_WIDGET_OPEN_ISSUE_FORM_REFERENCE.multitaskProcedureId,
          inputType: WORKFLOW_WIDGET_DATA_COLLECTION_TYPES.MULTITASK,
          subTasksStaticFormReferences,
          // There is no active/inactive functionality for multitasks yet,
          // so marking them alway active for now
          isActive: true,
          ...getIssueSubjectAssetFilters(subjectRules),
        }
      }
    )

    return [
      ...issueMultitaskOptions,
      ...formReferenceOptions,
    ] as IssueFormOptions
  },
})

export const issueProceduresFormReferencesState = selector({
  key: 'issueProceduresFormReferencesState',
  get: ({ get }): Record<string, string[]> => {
    const { issueMultitask } = get(issueDataCollectionRelatedState) || {}
    return _(issueMultitask?.procedures)
      .keyBy('id')
      .mapValues('subTasksStaticFormReferences')
      .value()
  },
})

export type IssueTaskDataCollectionForms = Record<
  string,
  Partial<DataCollectionForm>
>

export const issueTaskDataCollectionFormDefinitionState = atom({
  key: 'issueTaskDataCollectionFormDefinitionState',
  default: {} as IssueTaskDataCollectionForms,
})

export type IssueTaskForms = Record<string, DataCollectionForm>

export const issueTaskDataCollectionXFormsState = selector({
  key: 'issueTaskDataCollectionXFormsState',
  get: ({ get }): IssueTaskForms => {
    const { issueTask } = get(issueDataCollectionRelatedState) || {}
    const { dataCollectionForms } = issueTask || {}
    return _(dataCollectionForms)
      .filter({ formType: ISSUE_TASK_DATA_COLLECTION_FORM_TYPES.ODK_FORM })
      .keyBy('id')
      .value()
  },
})

export const issueTaskDataCollectionXFormSpecificationList = selector({
  key: 'issueTaskDataCollectionXFormSpecificationList',
  get: ({ get }): { [key: string]: SpecificationParameterValue[] } => {
    const formSpecs = get(issueTaskDataCollectionFormDefinitionState)
    return _.reduce(
      formSpecs,
      (acc, cur, key) => {
        const { xForm } = cur
        if (!xForm) return acc
        return {
          ...acc,
          [key]: getSpecificationParametersFromXForm(xForm),
        }
      },
      {}
    )
  },
})

export const issueTaskDataCollectionXFormSpecification = selector({
  key: 'issueTaskDataCollectionXFormSpecification',
  get: ({ get }): { [key: string]: SpecificationParameters } => {
    const formSpecs = get(issueTaskDataCollectionXFormSpecificationList)
    return _.reduce(
      formSpecs,
      (acc, cur, key) => {
        return {
          ...acc,
          [key]: _.keyBy(cur, 'id'),
        }
      },
      {}
    )
  },
})
