import _ from 'lodash'
import IconButton from 'components/common/IconButton'
import type { DataTableProps } from '../../types'

const SelectColumnHeader = ({
  list,
  selectedIdsSet,
  selectedItemsActions,
}: { list: [] } & Pick<
  DataTableProps,
  'selectedIdsSet' | 'selectedItemsActions'
>) => {
  const disabledIds = _(list).filter('disabled').map('id').value()
  const allDisabled = disabledIds.length === list.length
  if (allDisabled) return null

  const hasSelectedIds = _.size(selectedIdsSet) > 0
  return (
    <IconButton
      icon={hasSelectedIds ? 'MdCheckBox' : 'MdCheckBoxOutlineBlank'}
      onClick={() => {
        if (hasSelectedIds) {
          selectedItemsActions?.reset()
        } else {
          const allIds = _.map(list, 'id')
          selectedItemsActions?.selectAll(allIds, disabledIds)
        }
      }}
      size={24}
    />
  )
}

export default SelectColumnHeader
