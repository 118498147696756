// libraries
import {
  memo,
  useMemo,
  useRef,
  useEffect,
  useCallback,
  ReactElement,
  Dispatch,
  CSSProperties,
  PropsWithChildren,
} from 'react'
import _ from 'lodash'
import { VariableSizeList as List, areEqual } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

// constants
import { THEMES } from 'constants/colour'

import type { SelectedItemsActions } from 'components/common/List/hooks/useSelectableItems'

// components
import { Loading, EmptyList } from 'components/common'
import { IssueGeojson } from 'types/issue'
import { ThemeType } from 'types/common'
import IssueListItem from '../IssueListItem'

import scss from './index.module.scss'

const ROW_HEIGHT = 150

type IssueListProps = PropsWithChildren<{
  issues: IssueGeojson[]
  loading?: boolean
  currentActiveIssue?: IssueGeojson
  setCurrentActiveIssue: Dispatch<IssueGeojson | undefined>
  className?: string
  withBulkUpdate?: boolean
  theme?: ThemeType
  heightOffset?: number
  getMapInteractions?: () => void
  onCancel?: () => void
  selectedIdsSet?: Set<string>
  selectedItemsActions?: SelectedItemsActions
}>

type IssueListData = Pick<
  IssueListProps,
  | 'issues'
  | 'setCurrentActiveIssue'
  | 'withBulkUpdate'
  | 'getMapInteractions'
  | 'theme'
> & {
  setItemSize: (index: number, height: number) => void
  currentActiveIssueId?: string
  selectedIssuesIdSet?: IssueListProps['selectedIdsSet']
  selectedIssuesActions?: IssueListProps['selectedItemsActions']
}

const Row = memo(
  ({
    data,
    index,
    style,
  }: {
    data: IssueListData
    index: number
    style: CSSProperties
  }) => {
    const {
      issues,
      setCurrentActiveIssue,
      currentActiveIssueId,
      selectedIssuesIdSet,
      ...rest
    } = data
    const issue = issues[index]

    const { id, properties } = issue
    return (
      <div style={style}>
        <IssueListItem
          {...rest}
          index={index}
          key={properties.name}
          issue={issue}
          isActive={currentActiveIssueId === id}
          onCardClick={setCurrentActiveIssue}
          isSelected={selectedIssuesIdSet?.has(id)}
        />
      </div>
    )
  },
  areEqual
)

const IssueList = ({
  issues = [],
  loading = false,
  currentActiveIssue,
  setCurrentActiveIssue = _.noop,
  className,
  withBulkUpdate = true,
  theme = THEMES.light,
  heightOffset,
  getMapInteractions = _.noop,
  children,
  onCancel = _.noop,
  selectedIdsSet: selectedIssuesIdSet,
  selectedItemsActions: selectedIssuesActions,
}: IssueListProps): ReactElement => {
  const { id: currentActiveIssueId } = currentActiveIssue || {}
  const listRef = useRef<HTMLHtmlElement>()
  const sizeMap = useRef<HTMLHtmlElement>()

  useEffect(() => {
    if (loading) {
      setCurrentActiveIssue(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (currentActiveIssue) {
      const index = _.findIndex(issues, { id: currentActiveIssue.id })
      if (listRef.current) {
        listRef.current.scrollToItem(index, 'start')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentActiveIssue])

  const setItemSize = useCallback((index: number, size: number) => {
    if (sizeMap.current && _.get(sizeMap.current, index) !== size) {
      sizeMap.current = { ...sizeMap.current, [index]: size }
      if (listRef.current) {
        listRef.current.resetAfterIndex(0)
      }
    }
  }, [])

  const estimatedItemSize = useMemo((): number => {
    if (sizeMap.current) {
      const keys = Object.keys(sizeMap.current)
      const estimatedHeight = keys.reduce(
        (p, i) => p + _.get(sizeMap.current, i),
        0
      )
      return estimatedHeight / keys.length
    }
    return ROW_HEIGHT
  }, [])

  const itemData = useMemo(
    () => ({
      issues,
      setItemSize,
      setCurrentActiveIssue,
      currentActiveIssueId,
      selectedIssuesIdSet,
      selectedIssuesActions,
      withBulkUpdate,
      getMapInteractions,
      theme,
    }),
    [
      issues,
      setItemSize,
      setCurrentActiveIssue,
      currentActiveIssueId,
      selectedIssuesIdSet,
      selectedIssuesActions,
      withBulkUpdate,
      getMapInteractions,
      theme,
    ]
  )

  const getSize = useCallback(
    (index: number) => _.get(sizeMap.current, index, ROW_HEIGHT),
    []
  )

  const style = useMemo(() => {
    return heightOffset
      ? {
          height: `calc(100% - ${heightOffset}px)`,
        }
      : {}
  }, [heightOffset])

  const renderList = useCallback(
    () =>
      _.isEmpty(issues) ? (
        <EmptyList content='issues' theme={theme} />
      ) : (
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => {
            return (
              <List
                ref={listRef}
                className='List'
                height={height}
                itemCount={issues.length}
                itemData={itemData}
                itemSize={getSize}
                width={width}
                estimatedItemSize={estimatedItemSize}
              >
                {Row}
              </List>
            )
          }}
        </AutoSizer>
      ),
    [estimatedItemSize, getSize, issues, itemData, theme]
  )

  const issueContainerHeight = useMemo(
    () => `${children ? 'calc(100% - 60px)' : 'calc(100% - 4px)'}`,
    [children]
  )

  return (
    <div className={`${scss.container} ${className}`} style={style}>
      <div
        className={scss.issuesContainer}
        style={{
          height: issueContainerHeight,
        }}
      >
        {loading ? (
          <Loading onClick={onCancel} buttonContent='Pause Loading' />
        ) : (
          renderList()
        )}
      </div>
      {children}
    </div>
  )
}

export default IssueList
