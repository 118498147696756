import { useMemo, ReactElement, useRef } from 'react'
import _ from 'lodash'
import { useToggle } from 'react-use'
import styled from '@emotion/styled'
import Color from 'color'

import type { Payload } from 'types/common'
import type { Filter, FilterSpec } from 'types/filter'
import type { FormSelectProps } from 'components/common/Form/FormSelect'

// constants
import {
  PROPERTY_VARIABLE_TYPES,
  PROPERTY_VARIABLE_FORMATS,
  ISSUE_FORM_FILTER_TYPE,
  ASYNC_SELECT_FILTER_TYPE,
} from 'constants/filter'
import { BUTTON_VARIANTS } from 'components/common/Button'

// components
import { Star, IconButton, Button } from 'components/common'
import FilterDropdown from '../FilterDropdown'
import FilterForm, { SelectDropdown } from '../FilterForm'
import FilterAsyncDropdown from '../FilterAsyncDropdown'
import FilterTime from '../FilterTime'

import scss from './index.module.scss'

const FilterBadge = styled.div<{ vertical: boolean }>`
  display: flex;
  color: ${props => props.theme.primary};
  background-color: ${props => props.theme['secondary-light-500']};
  text-transform: uppercase;
  padding: ${props => (props.vertical ? '0' : '2.5px 8px')};
  margin: ${props => (props.vertical ? '5px' : '0px 5px')};
  font-size: 10px;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  &:hover {
    background-color: ${props =>
      Color(props.theme['secondary-light-500']).darken(0.1).string()};
  }
`
type AddFiltersProps = {
  filters: Payload<Filter>
  filtersSpecs: FilterSpec[]
  vertical?: boolean
  expandFilter?: boolean
  onChange: (newFilters: Payload, deleteKeys?: string[]) => void
  toggleFilterExpanded: (v: boolean) => void
}

type FilterComponentProps = Pick<AddFiltersProps, 'filters'> &
  Partial<
    Pick<FormSelectProps, 'options' | 'getOptions' | 'useOptionValueOnly'>
  > & {
    key: string
    label: string
    value?: Filter
    onPostChange?: (payload: Payload) => Payload
    Component?: ReactElement
    onChange: (selectedValues: string[]) => void
  }

const FilterToggle = ({ value: isActive, ...rest }: FilterComponentProps) => {
  return <Star {...rest} size={18} isActive={isActive as boolean} />
}

const FilterSelect = (props: FilterComponentProps) => {
  const { options, getOptions, useOptionValueOnly = true, ...rest } = props
  const newOptions = _.isFunction(getOptions) ? getOptions(props) : options
  return (
    <FilterDropdown
      {...rest}
      options={newOptions}
      useOptionValueOnly={useOptionValueOnly}
    />
  )
}

const FILTER_COMPONENTS_MAP = {
  [PROPERTY_VARIABLE_FORMATS.time]: FilterTime,
  [PROPERTY_VARIABLE_TYPES.boolean]: FilterToggle,
  [ISSUE_FORM_FILTER_TYPE]: FilterForm,
  [ASYNC_SELECT_FILTER_TYPE]: FilterAsyncDropdown,
}

const ICON_SIZE = 10

const FilterOptions = ({
  filteredSpecs,
  onChange,
}: {
  filteredSpecs: AddFiltersProps['filtersSpecs']
  onChange: AddFiltersProps['onChange']
}) => {
  return (
    <div className={scss.optionsContainer}>
      <div className={scss.subtitle}>Add Filter</div>
      {filteredSpecs && (
        <ul className={scss.list}>
          {_.map(filteredSpecs, spec => {
            const { label, key, icon } = spec
            return (
              <li
                key={key}
                className={scss.item}
                onClick={() => {
                  const newFilters = { [key]: undefined }
                  onChange(newFilters)
                }}
              >
                <IconButton
                  className='d-flex'
                  icon={icon ?? 'AiFillTool'}
                  size={ICON_SIZE}
                  width={ICON_SIZE}
                  height={ICON_SIZE}
                />
                <span>{label}</span>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

const renderFilterComponent =
  (type?: string) =>
  (
    props: Omit<FilterComponentProps, 'onChange'> & {
      onChange: (payload: Payload) => void
      component?: React.FC<FilterComponentProps>
    }
  ) => {
    const {
      key,
      label,
      value,
      filters,
      onChange,
      onPostChange,
      component,
      ...rest
    } = props
    if (!filters) return undefined

    const newProps = {
      ...rest,
      filters,
      title: label ?? key,
      value: value ?? filters[key],
      onChange: (selectedValues: string[]) => {
        const payload = _.isFunction(onPostChange)
          ? onPostChange({ key, selectedValues, filters })
          : { [key]: selectedValues }
        onChange(payload)
      },
    }

    const FilterComponent =
      component ?? _.get(FILTER_COMPONENTS_MAP, type ?? '') ?? FilterSelect

    return <FilterComponent {...newProps} />
  }

const AddFilters = ({
  filters,
  filtersSpecs,
  vertical = false,
  expandFilter = false,
  toggleFilterExpanded,
  onChange,
}: AddFiltersProps): ReactElement => {
  const [isAddNewDropdownOpen, toggleAddNewDropdownOpen] = useToggle(false)

  const ref = useRef(null)

  const memoizedFilters = useMemo(() => {
    const filtersKeys = _.keys(filters)
    if (_.isEmpty(filtersKeys)) return <></>

    const filtersSpecsKeyByKey = _.keyBy(filtersSpecs, 'key')
    return _(filters)
      .keys()
      .map(filterKey => {
        const filterSpec = filtersSpecsKeyByKey[filterKey]
        if (!filterSpec) return undefined

        const {
          type,
          enable = true,
          key,
          isClearable = true,
          ...rest
        } = filterSpec
        if (!enable) return undefined

        return (
          <FilterBadge vertical={vertical} key={key}>
            {renderFilterComponent(type)({
              ...rest,
              key,
              filters,
              vertical,
              onChange,
            })}
            {isClearable && (
              <IconButton
                icon='MdClear'
                testId='remove-filter-option'
                onClick={() => onChange({}, [key])}
              />
            )}
          </FilterBadge>
        )
      })
      .compact()
      .value()
  }, [filters, filtersSpecs, onChange, vertical])

  const filteredSpecs = useMemo(() => {
    const filterKeys = _.keys(filters)
    return _.reject(
      filtersSpecs,
      ({ key, enable = true }) => !enable || _.includes(filterKeys, key)
    )
  }, [filters, filtersSpecs])

  return (
    <div
      className={`d-flex align-items-center ${
        vertical ? 'flex-wrap px-0' : 'ms-2'
      }`}
    >
      {expandFilter && !_.isEmpty(filters) && (
        <div
          className={`d-flex align-items-center ${vertical ? 'flex-wrap' : ''}
        `}
        >
          {memoizedFilters}
        </div>
      )}
      {!_.isEmpty(filteredSpecs) && (
        <div className={`${vertical ? 'ms2' : ''}`}>
          <SelectDropdown
            isOpen={isAddNewDropdownOpen}
            onClose={toggleAddNewDropdownOpen}
            ref={ref}
            target={
              <Button
                variant={BUTTON_VARIANTS.secondary}
                onClick={() => toggleAddNewDropdownOpen()}
                icon='AiOutlinePlus'
                className={scss.addButton}
              />
            }
          >
            <FilterOptions
              filteredSpecs={filteredSpecs}
              onChange={(newFilters: Payload) => {
                toggleAddNewDropdownOpen(false)
                toggleFilterExpanded(true)
                onChange(newFilters)
              }}
            />
          </SelectDropdown>
        </div>
      )}
    </div>
  )
}
export default AddFilters
