import { ReactElement } from 'react'

// constants
import { MFA_TYPES } from 'constants/common'
import { USER_EDIT_MODE } from 'constants/user'

// types
import { UserSettingMode } from 'types/user'

// utils
import { formatPhone } from 'components/common/Form/PhoneField'

import type { MFATypes } from 'types/user'

// components
import { SecureAppIcon, TextMessageIcon } from 'components/icons'
import { useBranding } from 'hooks'
import TwoStepAuthenticationCard from './TwoStepAuthenticationCard'

// styles
import scss from './index.module.scss'

const TwoStepAuthentication = ({
  mfaPreferred,
  phone,
  onModeChange,
}: {
  mfaPreferred?: MFATypes
  phone?: string
  onModeChange: (v: UserSettingMode) => void
}): ReactElement => {
  const {
    colour: { primary },
  } = useBranding()

  // [HUD-253] 15 July 2025 hiding an option to use a phone number as a MFA option
  const isSmsMfaEnabled = mfaPreferred === MFA_TYPES.SMS_MFA

  return (
    <div className={`row g-0 mb-4 ${scss.authTypeWrapper}`}>
      {isSmsMfaEnabled && (
        <TwoStepAuthenticationCard
          mfaPreferred={mfaPreferred}
          authTypeInfo={formatPhone(phone)}
          authTypeTitle='Text message'
          mfaType={MFA_TYPES.SMS_MFA}
          mode={USER_EDIT_MODE.mfaSms}
          cardIcon={<TextMessageIcon width={52} height={52} color={primary} />}
          onModeChange={onModeChange}
          bordered
        />
      )}

      <TwoStepAuthenticationCard
        mfaPreferred={mfaPreferred}
        authTypeInfo='Enabled'
        authTypeTitle='Security app'
        mfaType={MFA_TYPES.SOFTWARE_TOKEN_MFA}
        mode={USER_EDIT_MODE.mfaApp}
        cardIcon={<SecureAppIcon width={52} height={52} color={primary} />}
        onModeChange={onModeChange}
      />
    </div>
  )
}

export default TwoStepAuthentication
