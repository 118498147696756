import { useMemo } from 'react'
import keymirror from 'keymirror'
import _ from 'lodash'

// constants
import { APP_NAMES } from 'constants/common'

// utils
import { useAuthCurrentUser } from 'contexts/hooks'

// components
import SecurityAndPolicy from 'components/settings/SecurityAndPolicy'
import UserProfile from 'components/settings/UserProfile'
import Versions from 'components/settings/Versions'
import FormGallery from 'routers/pages/FormGallery'

export const NOTIFICATION_CLOSE_TIME = 5000

export const FEATURES = {
  WORKFLOW_GEOFENCE_ANCILLARY_DATA: 'workflowGeofenceAncillaryData',
  WORKFLOW: 'rulesEngine',
  TILE_LAYER: 'tileLayer',
  ANCILLARY_DATA: 'assetsManagement',
  ISSUE: 'issue',
  SITE: 'site',
  ASSET: 'asset',
  MAPS: 'maps',
  DYNAMIC_SCHEDULING: 'dynamicScheduling',
  CREATE_USER: 'createUser',
  JSON_FORM: 'jsonForm',
  JSON_FORM_LIST_BUILDER: 'jsonFormListBuilder',
  LDAR_SIM_FILE_UPLOADER: 'ldarSimFileUploader',
  RECONCILIATION: 'reconciliation',
  DISPLAY_VENTING_EVENT_LOCATION_ON_MAP_IN_GEMS:
    'displayVentingEventLocationOnMapInGems',
  DISPLAY_QUBE_ALARM_LOCATION_ON_MAP_IN_GEMS:
    'displayQubeAlarmLocationOnMapInGems',
  ENABLE_GROSS_EMISSIONS_DASHBOARD: 'enableGrossEmissionsDashboard',
  EMISSION_EVENTS: 'emissionEvents',
  GEMS_CONFIGURATION: 'gemsConfiguration',
  ALLOW_DONE_ACTION_FOR_DAC_SITES_FLYOVER_IN_GEMS:
    'allowDoneActionForDacSitesFlyoverInGems',
  STUDIO_DASHBOARDS: 'studioDashboards',
  FUGITIVES_DASHBOARD_SETTINGS: 'resolveFugitivesDashboardSettings',
  SERP_REPORTING: 'serfReporting',
  ALLOW_REPROCESS_RECONCILIATION_FOR_A_CLOSED_MONTH:
    'allowReprocessReconciliationForAClosedMonth',
  CREATE_AN_ISSUE_FROM_AN_ACTION_ON_A_DETECTION_IN_AN_INBOX:
    'createAnIssueFromAnActionOnADetectionInAnInbox',
  ALLOW_RECONCILIATION_REOPENING_FOR_THE_SITE:
    'allowReconciliationReopeningForTheSite',
  OPS_COMMENT_SETTINGS: 'opsCommentSettings',
  CAUSE_ANALYSIS: 'causeAnalysisAction',
  REQUEST_VFB_REPORT: 'requestVfbReportAction',
  REQUEST_OGI: 'requestOgiAction',
}

export const MODAL_SIZES = keymirror({
  extraSmall: null,
  small: null,
  regular: null,
  large: null,
})

export const MODAL_TYPES = keymirror({
  warning: null,
  info: null,
  danger: null,
})

export const SETTINGS_TYPES = keymirror({
  userProfile: null,
  securityAndPrivacy: null,
  versions: null,
  formDesigner: null,
})

export const useSettingsMenu = () => {
  const { appName } = useAuthCurrentUser()

  const settingsMenu = useMemo(
    () => [
      {
        label: 'MY SETTINGS',
        group: [
          {
            label: 'My profile',
            value: SETTINGS_TYPES.userProfile,
            icon: 'FaRegUserCircle',
            component: UserProfile,
          },
          {
            label: 'Security and Privacy',
            value: SETTINGS_TYPES.securityAndPrivacy,
            icon: 'MdSecurity',
            component: SecurityAndPolicy,
          },
          {
            label: 'Versions',
            value: SETTINGS_TYPES.versions,
            component: Versions,
          },
        ],
      },
      ...(appName !== APP_NAMES.methane
        ? [
            {
              label: 'APP CONFIGURATION',
              group: [
                {
                  label: 'Form designer',
                  value: SETTINGS_TYPES.formDesigner,
                  icon: 'FormBuilderIcon',
                  component: FormGallery,
                  isExpanded: true,
                  enabled: FEATURES.JSON_FORM,
                  sidebarRouteEnabled: true,
                },
              ],
            },
          ]
        : []),
    ],
    [appName]
  )

  const settingsMenuWithoutGroup = useMemo(
    () => _.flatMap(settingsMenu, 'group'),
    [settingsMenu]
  )
  const settingsMenuKeyByValue = useMemo(
    () => _.keyBy(settingsMenuWithoutGroup, 'value'),
    [settingsMenuWithoutGroup]
  )

  return {
    settingsMenu,
    settingsMenuWithoutGroup,
    settingsMenuKeyByValue,
  }
}

export const TOOLTIP_PLACEMENT = keymirror({
  left: null,
  right: null,
  top: null,
  bottom: null,
  topLeft: null,
  topRight: null,
  bottomLeft: null,
  bottomRight: null,
  rightTop: null,
  rightBottom: null,
  leftTop: null,
  leftBottom: null,
})

export const USER_PREFERENCES = keymirror({
  sidebarRoutes: null,
  filters: null,
  favoriteMaps: null,
  favoriteFormLists: null,
  enableTimezone: null,
  galleryOptions: null,
})
