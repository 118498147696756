// libraries
import _ from 'lodash'
import wellknown from 'wellknown'

// utils
import {
  getCleanPropertyName,
  getJsonFormIdFromAssetProfileId,
} from 'helpers/assetProfile'
import { getFieldValue } from 'helpers/asset'
import { getAllJSONFormProperties } from 'helpers/issue'

import type { Payload, PropertiesMetadata } from 'types/common'
import type { IssueTaskDataCollectionForms } from 'recoilStore/issuesStore'
import { MapLayerData } from 'types/map'

const GEOMETRY_KEY = 'geometry'

export const getDataMetadata = (
  propertyOptions: PropertiesMetadata
): PropertiesMetadata => [
  ...propertyOptions,
  {
    label: 'Geometry',
    type: 'string',
    value: GEOMETRY_KEY,
  },
]

/** Sorts table columns according to the properties order in a form */
export const sortMetadataByFormOrder = ({
  metadata,
  formUiSchema,
}: {
  metadata: PropertiesMetadata
  formUiSchema: Payload
}): PropertiesMetadata => {
  const formOrder = formUiSchema['ui:order'] as string[]

  return _.sortBy(metadata, item => {
    const propertyName = getCleanPropertyName(item.value)
    const index = formOrder.indexOf(propertyName)
    // If a field doesn't exist in 'formOrder', put at the end by default
    return index !== -1 ? index : metadata.length
  })
}

export const convertGeojsonToTableData = (
  geojsonRows: MapLayerData,
  issueTaskDataCollectionFormsKeyByFormReference: IssueTaskDataCollectionForms
): MapLayerData => {
  // Getting the related profileId
  const { profile } = _.first(geojsonRows) || {}
  // Transforming it into jsonFormId
  const formId = getJsonFormIdFromAssetProfileId(profile)
  // Trying to get a form definition
  const dataCollectionForm =
    issueTaskDataCollectionFormsKeyByFormReference[formId]
  const propertiesSchema = dataCollectionForm?.jsonFormBody
    ? getAllJSONFormProperties(dataCollectionForm.jsonFormBody)
    : null

  return _.map(geojsonRows, row => {
    // If schema exists
    const transformedProperties = propertiesSchema
      ? _.mapValues(row.properties, (value, key) =>
          getFieldValue({
            value,
            fieldSchema: propertiesSchema[getCleanPropertyName(key)],
          })
        )
      : row.properties

    return {
      ...transformedProperties,
      [GEOMETRY_KEY]: wellknown.stringify(row.geometry),
    }
  })
}
