import { CSSProperties, ReactElement } from 'react'
import { useToggle } from 'react-use'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { InfoTooltip } from 'components/common/Tooltip'
import { IconButton } from 'components/common/'

interface TitleWithTooltipProps {
  title?: string
  style?: CSSProperties
  className?: string
  tooltip?: string
  checkable?: boolean
  onChecked?: (checked: boolean) => void
}

const TitleWithTooltip = ({
  title = '',
  style,
  className,
  tooltip,
  checkable,
  onChecked,
}: TitleWithTooltipProps): ReactElement => {
  const [isSelect, toggleSelect] = useToggle(false)

  const isTooltipShown = !!tooltip

  const handleCheckClick = () => {
    onChecked?.(!isSelect)
    toggleSelect()
  }

  return (
    <div className={`groupOptionLabel ${className}`} style={style}>
      {checkable && (
        <IconButton
          icon={isSelect ? 'MdCheckBox' : 'MdCheckBoxOutlineBlank'}
          onClick={handleCheckClick}
          size={24}
          className='me-1'
        />
      )}
      {title}
      {isTooltipShown && (
        <InfoTooltip placement={TOOLTIP_PLACEMENT.right} message={tooltip} />
      )}
    </div>
  )
}

export default TitleWithTooltip
