import { useMemo } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  TypePolicy,
} from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { errorLink } from 'apollo/links'

import { useConfigStateValue } from 'contexts'
import { isDevEnvironment } from 'helpers/utils'

/**
 * ! Add a type here to fix this Apollo error:
 * "Cache data may be lost when replacing the {typeName} field of a Query object."
 */
const TYPES_TO_FIX_BY_ID_QUERY_CACHING = ['Issues', 'EmissionObservations']

const useInitApolloClient = () => {
  const { graphqlApi } = useConfigStateValue() || {}

  return useMemo(() => {
    const isDevEnv = isDevEnvironment()
    return new ApolloClient({
      cache: new InMemoryCache({
        typePolicies: {
          ...TYPES_TO_FIX_BY_ID_QUERY_CACHING.reduce<
            Record<string, TypePolicy>
          >(
            (acc, typeName) => ({
              ...acc,
              [typeName]: {
                keyFields: [],
                fields: {
                  byId: {
                    merge(existing, incoming, { mergeObjects }) {
                      return mergeObjects(existing, incoming)
                    },
                  },
                },
              },
            }),
            {}
          ),
          // This is the type of a query domain. For example, if we're working with the query 'emissionEvents.inbox',
          // that means that 'emissionEvents' is a domain. You can easily find its type in Apollo Studio.
          EmissionEvents: {
            fields: {
              // Basically the name of the query
              // inbox: relayStylePagination(),
              all: relayStylePagination(),
            },
          },
        },
      }),
      link: ApolloLink.from([
        errorLink,
        new HttpLink({
          uri: isDevEnv ? 'http://localhost:3000/api/graphql' : graphqlApi,
        }),
      ]),
      connectToDevTools: isDevEnv,
    })
  }, [graphqlApi])
}

export default useInitApolloClient
