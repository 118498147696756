import { useMemo } from 'react'
import { useRecoilValueLoadable } from 'recoil'

// utils
import { siteEquipmentsQuery } from 'app/MissionControlMethaneSolution/recoilStore/siteStore'

import type { SiteEquipmentOption } from 'types/sites'

const useSiteEquipments = ({
  assetId,
}: {
  assetId?: string
}): {
  isLoading: boolean
  options: SiteEquipmentOption[]
} => {
  const siteEquipmentsLoadable = useRecoilValueLoadable(
    siteEquipmentsQuery(assetId)
  )
  const equipmentOptions = useMemo(
    () =>
      siteEquipmentsLoadable.state === 'hasValue'
        ? siteEquipmentsLoadable.contents
        : [],
    [siteEquipmentsLoadable.contents, siteEquipmentsLoadable.state]
  )

  return {
    isLoading: siteEquipmentsLoadable.state === 'loading',
    options: equipmentOptions,
  }
}

export default useSiteEquipments
