import { ReactElement } from 'react'
import _ from 'lodash'

import type { MultiSelectProp } from 'components/common/MultiSelect'
import type { OptionProps } from 'react-select'
import type { Option } from 'types/common'
import scss from './index.module.scss'

const FilterCustomOption =
  (formatOptionLabel: MultiSelectProp['formatOptionLabel']) =>
  (props: OptionProps & { data: Option }): ReactElement => {
    const {
      data,
      getStyles,
      label,
      isSelected,
      innerRef,
      innerProps,
      isMulti,
    } = props

    const { labelExtras: { description } = {} } = data

    return (
      <div
        ref={innerRef}
        style={getStyles('option', props)}
        className={`d-flex ${
          description ? 'align-items-baseline' : 'align-items-center'
        }`}
        {...innerProps}
      >
        {isMulti && (
          <input type='checkbox' checked={isSelected} onChange={_.noop} />
        )}
        <div>
          <div className={scss.label}>
            {formatOptionLabel ? formatOptionLabel(data) : label}
          </div>
          {description && (
            <div className={scss.optionSecondary}>{description}</div>
          )}
        </div>
      </div>
    )
  }

export default FilterCustomOption
