import { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useMount, useMountedState, useUnmount } from 'react-use'
// Import the Canvas renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from 'echarts/renderers'
import * as echarts from 'echarts/core'
import _ from 'lodash'

// Only load needed components instead of loading all components
// For more components, please refer https://github.com/apache/echarts/blob/a4df6a1fe1a5e8fb58a0e3536db07dc6f819a655/src/echarts.all.ts
import {
  BarChart,
  LineChart,
  GaugeChart,
  ScatterChart,
  PieChart,
  SankeyChart,
  EffectScatterChart,
  CustomChart,
} from 'echarts/charts'
import {
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  ToolboxComponent,
  DatasetComponent,
  GridComponent,
  TransformComponent,
  MarkLineComponent,
  MarkAreaComponent,
  GeoComponent,
  GraphicComponent,
} from 'echarts/components'

import { DEFAULT_WIDGET_SIZE_LARGE } from 'constants/widget'

import type { Payload } from 'types/common'

// Register the required components
echarts.use([
  PieChart,
  BarChart,
  LineChart,
  GaugeChart,
  ScatterChart,
  GraphicComponent,
  SankeyChart,
  CustomChart,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  ToolboxComponent,
  CanvasRenderer,
  DatasetComponent,
  GridComponent,
  VisualMapComponent,
  TransformComponent,
  MarkLineComponent,
  MarkAreaComponent,
  GeoComponent,
  EffectScatterChart,
])

const useEcharts = ({
  chartRef,
  getOptions,
  customStyle,
  size,
  setWidgetInstance = _.noop,
  mapConfig,
}: {
  chartRef: React.MutableRefObject<HTMLDivElement>
  getOptions: () => Payload
  customStyle?: Payload
  size?: string
  setWidgetInstance?: React.Dispatch<React.SetStateAction<undefined>>
  mapConfig?: {
    mapName: string
    geoJSON: Record<string, unknown>
    specialAreas?: Record<string, unknown>
  }
}): {
  chartInstance?: echarts.ECharts
  style: Payload
  reDrawChart: () => void
} => {
  const [chartInstance, setChartInstance] = useState<echarts.ECharts>()
  const [dimensions, setDimensions] = useState({})
  const isMounted = useMountedState()

  const getStyleOptions = useCallback(() => {
    const { width: dimensionsWidth, height: dimensionsHeight } = dimensions

    const height = dimensionsWidth || chartRef?.current?.clientHeight
    const width = dimensionsHeight || chartRef?.current?.clientWidth

    const expanded = size === 'small' ? false : height > 90 && width > 382
    return getOptions({ expanded, width, height })
  }, [chartRef, dimensions, getOptions, size])

  useMount(() => {
    _.delay(() => {
      if (!isMounted() || !chartRef.current) return

      const chart = echarts.init(chartRef.current)

      if (mapConfig) {
        echarts.registerMap(
          mapConfig.mapName,
          mapConfig.geoJSON,
          mapConfig.specialAreas
        )
      }

      const options = getStyleOptions(chartRef, getOptions)
      chart.setOption(options)
      setWidgetInstance(chart)
      setChartInstance(chart)
    }, 100)
  })

  useUnmount(() => {
    chartInstance?.dispose()
  })

  const style = useMemo(
    () => customStyle ?? DEFAULT_WIDGET_SIZE_LARGE,
    [customStyle]
  )

  useEffect(() => {
    // do not merge with previous option
    chartInstance?.setOption(getStyleOptions(chartRef, getOptions), {
      notMerge: true,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartInstance, getOptions])

  const observer = useRef(
    new ResizeObserver(entries => {
      if (!isMounted()) return

      const { width, height } = entries[0].contentRect
      setDimensions({ width: Math.round(width), height: Math.round(height) })
    })
  )

  useEffect(() => {
    if (chartRef?.current) {
      observer.current.observe(chartRef.current)
    }

    return () => {
      if (chartRef?.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.current.unobserve(chartRef.current)
      }
    }
  }, [chartRef, observer])

  const handleResize = _.throttle(() => {
    if (chartInstance) {
      chartInstance.resize()
    }
  }, 100)

  useEffect(() => {
    handleResize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensions])

  const reDrawChart = useCallback(() => {
    chartInstance?.setOption(getOptions(), true)
  }, [chartInstance, getOptions])

  window.addEventListener('resize', handleResize)

  return { chartInstance, style, reDrawChart }
}

export default useEcharts
