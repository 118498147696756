import Button, { BUTTON_VARIANTS, BUTTON_SIZES } from 'components/common/Button'

import scss from './index.module.scss'

const LoadingMoreSpinner = () => (
  <div className={scss.tableLoadingMore}>
    <Button
      variant={BUTTON_VARIANTS.link}
      size={BUTTON_SIZES.large}
      isLoading
    />
  </div>
)

export default LoadingMoreSpinner
