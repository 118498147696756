import _ from 'lodash'
import moment from 'moment-timezone'

import type { Payload } from 'types/common'
import { replaceDynamicProperties, isValueEmpty } from './utils'
import { isValidLatitude, isValidLongitude } from './geojson'

export type Validator = (value: unknown) => string | undefined
type Errors = Record<string, string>

export const composeValidators =
  (...validators: Validator[]) =>
  (value: unknown): string | undefined =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    )

export const validateRequiredKey =
  (key: string, label: string, isPlural = false) =>
  (values: Payload = {}, errors: Payload = {}): void => {
    const value = _.get(values, key)

    if (isValueEmpty(value)) {
      errors[key] = `${label} ${isPlural ? 'are' : 'is'} required`
    }
  }

export const validateKeyWithLimit =
  (key: string, label: string, limit = 50, isRequired = true) =>
  (values: Record<string, string> = {}, errors: Errors = {}): void => {
    const value = values[key]

    if (isRequired) {
      validateRequiredKey(key, label)(values, errors)
    }

    if (value?.length > limit) {
      errors[key] = `${label} must be less than ${limit} characters`
    }
  }

export const validateKeyLatitude =
  (key: string, label: string) =>
  (values: Payload = {}, errors: Errors = {}): void => {
    let value = values[key]

    if (!_.isNumber(value)) {
      value = Number(value)
    }

    if (!isValidLatitude(value)) {
      errors[key] = `${label} must be between -90 and +90`
    }
  }

export const validateKeyLongitude =
  (key: string, label: string) =>
  (values: Payload = {}, errors: Errors = {}): void => {
    let value = values[key]

    if (!_.isNumber(value)) {
      value = Number(value)
    }

    if (!isValidLongitude(value)) {
      errors[key] = `${label} must be between -180 and +180`
    }
  }

export const validateKeyWithDynamicProperties =
  ({
    key,
    label,
    limit,
    placeholder,
    replacementLength,
    required,
  }: {
    key: string
    label: string
    limit?: number
    placeholder?: string
    replacementLength?: number
    required?: boolean
  }) =>
  (values: Record<string, string> = {}, errors: Errors = {}): void => {
    const valueWithoutDynamicProperties = replaceDynamicProperties({
      str: values[key],
      placeholder,
      replacementLength,
    })

    validateKeyWithLimit(
      key,
      label,
      limit,
      required
    )({ ...values, [key]: valueWithoutDynamicProperties }, errors)
  }

export const validatePhoneNumber = (
  v: string,
  { required = true }: { required?: boolean } = {}
): string | undefined => {
  // If the value is empty and the field isn't required, then skip the validation for now
  if (!v && !required) return undefined

  const message = 'Phone Number must be 10 digits'
  const strippedValue = v?.replace('+1', '')
  if (!strippedValue) {
    return message
  }
  const isOnlyNumbers = /^\d*$/.test(strippedValue)
  const isTenDigits = strippedValue.length === 10
  const isValid = isTenDigits && isOnlyNumbers
  return isValid ? undefined : message
}

export const validateEmail = (v: string): boolean => {
  if (!v) {
    return false
  }

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(v)
}

export const validateEmailAddress = (v?: string): string | undefined => {
  if (!v) {
    return undefined
  }

  const message = 'Invalid email address'
  return validateEmail(v) ? undefined : message
}

export const getValidationMessage = (value: string | number): string =>
  `${value} is not valid`

export const getRequiredMessage = (value: string): string =>
  `${value} is required`

export const getDateRangedMessage = (value: string): string =>
  `${value} is not valid in date range`

export const isNotEmptyValidation = (value: string): boolean =>
  _.isNil(value) === false

export const isDateRangeValidation = (
  startDate: string,
  endDate: string
): boolean => moment(endDate).isAfter(startDate)

export const validateUsername = (
  values: { username: string },
  errors: Payload
): Payload => {
  const { username } = values
  validateRequiredKey('username', 'Email')(values, errors)
  if (!validateEmail(username)) {
    errors.username = 'Email must be valid'
  }
  return errors
}

export const isUrlValid = (url: string): boolean => {
  let urlData

  try {
    urlData = new URL(url)
  } catch (err) {
    return false
  }

  return urlData.protocol === 'http:' || urlData.protocol === 'https:'
}
