import keymirror from 'keymirror'

export const LAYER_ROUTES = keymirror({
  filters: null,
  styling: null,
  profile: null,
  widgets: null,
})

export const PAGE_EDIT_MODE_KEYWORD = 'edit'

const EDIT_SUFFIX = `?${PAGE_EDIT_MODE_KEYWORD}`

export const LOGIN_URL = '/login'
export const RESET_PASSWORD_URL = '/login/reset'
export const NEW_PASSWORD_URL = '/login/new'
export const SETTINGS_URL = '/settings'
export const SETTING_URL = `${SETTINGS_URL}/:tab`
export const USERS_URL = '/users'
export const MAPS_URL = '/maps'
export const MAP_URL = '/maps/:mapId'
export const MAP_VIEW_URL = `${MAP_URL}/view`
export const MAP_EDIT_URL = `${MAP_URL}${EDIT_SUFFIX}`
export const MAP_LAYER_URL = `${MAP_URL}/:layerId`
export const MAP_LAYER_ROUTE_URL = `${MAP_LAYER_URL}/:routeName`
export const LAYER_WIDGET_URL = `${MAP_LAYER_URL}/${LAYER_ROUTES.widgets}/:widgetId`

export const WORKFLOWS_URL = '/workflows'
export const WORKFLOW_NEW_URL = `${WORKFLOWS_URL}/new`
export const WORKFLOW_VIEW_URL = `${WORKFLOWS_URL}/:id`
export const WORKFLOW_EDIT_URL = `${WORKFLOWS_URL}/:id/${PAGE_EDIT_MODE_KEYWORD}`

export const ASSETS_URL = '/assets'
export const ASSETS_PROFILES_URL = `${ASSETS_URL}/profiles`
export const ASSETS_PROFILE_VIEW_URL = `${ASSETS_PROFILES_URL}/:id`
export const ASSETS_PROFILE_EDIT_URL = `${ASSETS_PROFILE_VIEW_URL}${EDIT_SUFFIX}`
export const ASSETS_PRINT_PREVIEW_URL = `${ASSETS_PROFILE_VIEW_URL}/print/:assetId`

export const ANCILLARY_DATA_URL = '/ancillary_data'

export const ISSUES_URL = '/issues'

export const SITES_URL = '/sites'
export const SITE_NEW_URL = `${SITES_URL}/new`
export const SITE_VIEW_URL = `${SITES_URL}/:id`
export const SITE_EDIT_URL = `${SITE_VIEW_URL}${EDIT_SUFFIX}`

export const FORMS_URL = '/forms'
export const FORM_DESIGNER_NEW_URL = `${FORMS_URL}/new`
export const FORM_DESIGNER_VIEW_URL = `${FORMS_URL}/:id`
export const FORM_DESIGNER_EDIT_URL = `${FORMS_URL}/:id/${PAGE_EDIT_MODE_KEYWORD}`

export const DEV_UI_PLAYGROUND_URL = '/ui'

export const SCHEDULE_OPTIMIZATION_URL = '/dynamicScheduling'
export const LDAR_SIM_FILE_UPLOADER_URL = '/LDARSimulator'

export const DASHBOARD_URL = '/dashboards'

export const URLS = {
  ANCILLARY_DATA: ANCILLARY_DATA_URL,
  ASSETS: ASSETS_URL,
  ASSETS_PROFILES: ASSETS_PROFILES_URL,
  ASSETS_PROFILE_VIEW: ASSETS_PROFILE_VIEW_URL,
  ASSETS_PROFILE_EDIT: ASSETS_PROFILE_EDIT_URL,
  ASSETS_PRINT_PREVIEW: ASSETS_PRINT_PREVIEW_URL,
  ISSUES: ISSUES_URL,
  LOGIN: LOGIN_URL,
  RESET_PASSWORD: RESET_PASSWORD_URL,
  NEW_PASSWORD: NEW_PASSWORD_URL,
  MAPS: MAPS_URL,
  MAP: MAP_URL,
  MAP_EDIT: MAP_EDIT_URL,
  MAP_VIEW: MAP_VIEW_URL,
  MAP_LAYER: MAP_LAYER_URL,
  MAP_LAYER_EDIT: `${MAP_LAYER_URL}${EDIT_SUFFIX}`,
  MAP_LAYER_ROUTE: MAP_LAYER_ROUTE_URL,
  MAP_LAYER_WIDGET: LAYER_WIDGET_URL,
  MAP_LAYER_WIDGET_EDIT: `${LAYER_WIDGET_URL}${EDIT_SUFFIX}`,
  WORKFLOWS: WORKFLOWS_URL,
  WORKFLOW_VIEW: WORKFLOW_VIEW_URL,
  WORKFLOW_NEW: WORKFLOW_NEW_URL,
  WORKFLOW_EDIT: WORKFLOW_EDIT_URL,
  SITES: SITES_URL,
  SITE_VIEW: SITE_VIEW_URL,
  SITE_EDIT: SITE_EDIT_URL,
  SETTINGS: SETTINGS_URL,
  SETTING: SETTING_URL,
  FORMS: FORMS_URL,
  FORM_DESIGNER_VIEW: FORM_DESIGNER_VIEW_URL,
  FORM_DESIGNER_EDIT: FORM_DESIGNER_EDIT_URL,
  FORM_DESIGNER_NEW: FORM_DESIGNER_NEW_URL,
  USERS: USERS_URL,
  DEV_UI_PLAYGROUND: DEV_UI_PLAYGROUND_URL,
  SCHEDULE_OPTIMIZATION: SCHEDULE_OPTIMIZATION_URL,
  LDAR_SIM_FILE_UPLOADER: LDAR_SIM_FILE_UPLOADER_URL,
  DASHBOARD: DASHBOARD_URL,
}
