import { ReactNode, PropsWithChildren, ReactElement, useRef } from 'react'
import _ from 'lodash'
import { CSSTransition } from 'react-transition-group'

// constants
import { BLADE_SIZES, BLADE_POSITION, BLADE_VARIANT } from 'constants/common'

import { stopEventDefaultAndPropagation } from 'helpers/utils'

// types
import type { BladeSize, BladePosition, BladeVariant } from 'types/common'
import type { IconButtonProps } from 'components/common/IconButton'
import type { ButtonProps } from 'components/common/Button'

import BladeFooter from './BladeFooter'
import BladeHeader from './BladeHeader'
import BladeContent from './BladeContent'
import { StyledBlade, StyledBladeOverlay } from './styles'

import scss from './index.module.scss'

const REGULAR_OVERLAY_TRANSITION_TIMEOUT = 150
export const REGULAR_BLADE_TRANSITION_TIMEOUT = 300

const MODAL_OVERLAY_TRANSITION_TIMEOUT = 200
const MODAL_BLADE_TRANSITION_TIMEOUT = 600

export type BladeProps = {
  className?: string
  position?: BladePosition
  variant?: BladeVariant
  size?: BladeSize
  testId?: string
  customHeader?: ReactNode
  title?: string | ReactNode
  titleIconName?: string
  titleIconSize?: number
  subtitle?: ReactElement | string
  actions?: IconButtonProps[]
  onBack?: null | (() => void)
  onClose?: () => void
  customFooter?: ReactNode
  footerButtons?: (ButtonProps & { id: string })[]
  footerGap?: number
  customHeight?: string
  customMargin?: string
  enableOverlay?: boolean
  enableTransition?: boolean
  show?: boolean
  enableScrollableContent?: boolean
  zIndex?: number
}

/**
 * @param props.className - additional 'className' that will be applied to the container
 * @param props.position - can be right or left
 * @param props.variant - 'docked' means that the panel will stick to the left/right side of a window,
 * 'floating' means that the panel will be positioned absolutely on top of a content
 * @param props.size - width of the panel
 * @param props.testId - 'data-testid', default to 'left-blade' for backward compatibility
 * @param props.customHeader - custom jsx for header
 * @param props.title - panel title
 * @param props.titleIconName - icon name from 'components/icons'
 * @param props.titleIconSize - icon size
 * @param props.subtitle - the text that is displayed under the title
 * @param props.actions - list of props for <IconButton> in BladeHeader
 * @param props.onBack - enables go-back arrow
 * @param props.onClose - enables x-close button
 * @param props.customFooter - custom jsx for footer
 * @param props.footerGap - gap between buttons in a footer
 * @param props.footerButtons - list of props for <Buttons />
 * @param props.customHeight - custom height for a panel.
 * If passed, the panel will be positioned statically instead of absolutely
 * @param props.customMargin - custom margin for the panel. Can be used together with the 'customHeight'
 * @param props.enableOverlay - adds backdrop overlay to the panel (like in modals)
 * @param props.enableTransition - enables animation on panel opening/closing, default 'true'
 * @param props.show - goes to 'in' prop in CSSTransition component.
 * Makes sense only if enableTransition === true
 * @param props.enableScrollableContent - enables scrollable content wrapper with 'faders'
 * @param props.zIndex - z-index of the panel
 */
const Blade = ({
  className = '',
  position = BLADE_POSITION.right,
  variant = BLADE_VARIANT.floating,
  size = BLADE_SIZES.default,
  testId = 'left-blade',
  customHeader,
  title,
  titleIconName,
  titleIconSize,
  subtitle,
  actions,
  onBack,
  onClose,
  customFooter,
  footerGap = 12,
  footerButtons,
  customHeight,
  customMargin,
  enableOverlay = false,
  enableTransition = true,
  show = true,
  enableScrollableContent = true,
  zIndex,
  children,
}: PropsWithChildren<BladeProps>): ReactElement => {
  const bladeOverlayRef = useRef(null)

  const panelRef = useRef(null)

  const isModalBlade = size === BLADE_SIZES.modal

  const panel = (
    <StyledBlade
      ref={panelRef}
      className={`${scss.blade} ${className}`}
      data-testid={testId}
      size={size}
      position={position}
      variant={variant}
      customHeight={customHeight}
      customMargin={customMargin}
      zIndex={zIndex}
    >
      {/* Allow 'null' so the header/footer can be omitted */}
      {customHeader || _.isNull(customHeader) ? (
        customHeader
      ) : (
        <BladeHeader
          title={title}
          titleIconName={titleIconName}
          titleIconSize={titleIconSize}
          subtitle={subtitle}
          actions={actions}
          onBack={onBack}
          onClose={onClose}
        />
      )}

      {enableScrollableContent ? (
        <BladeContent>{children}</BladeContent>
      ) : (
        children
      )}

      {customFooter || _.isNull(customFooter) ? (
        customFooter
      ) : (
        <BladeFooter gap={footerGap} buttons={footerButtons} />
      )}
    </StyledBlade>
  )

  const bladeOverlay = (
    <StyledBladeOverlay
      ref={bladeOverlayRef}
      onClick={e => {
        stopEventDefaultAndPropagation(e)
        onClose?.()
      }}
      data-testid='blade-overlay'
    />
  )

  return (
    <>
      {enableOverlay &&
        (enableTransition ? (
          <CSSTransition
            nodeRef={bladeOverlayRef}
            in={show}
            timeout={
              isModalBlade
                ? MODAL_OVERLAY_TRANSITION_TIMEOUT
                : REGULAR_OVERLAY_TRANSITION_TIMEOUT
            }
            classNames='fadeIn'
            mountOnEnter
            unmountOnExit
          >
            {bladeOverlay}
          </CSSTransition>
        ) : (
          bladeOverlay
        ))}

      {enableTransition ? (
        <CSSTransition
          nodeRef={panelRef}
          in={show}
          timeout={
            isModalBlade
              ? MODAL_BLADE_TRANSITION_TIMEOUT
              : REGULAR_BLADE_TRANSITION_TIMEOUT
          }
          classNames={
            position === BLADE_POSITION.right
              ? 'panelSlideInRight'
              : 'panelSlideInLeft'
          }
          unmountOnExit
        >
          {panel}
        </CSSTransition>
      ) : (
        panel
      )}
    </>
  )
}

export default Blade
