export { FaArrowTrendDown, FaArrowTrendUp } from 'react-icons/fa6'

export {
  AiFillAppstore,
  AiFillCheckCircle,
  AiFillCopy,
  AiFillEdit,
  AiFillInfoCircle,
  AiFillPushpin,
  AiFillSetting,
  AiFillStar,
  AiFillTool,
  AiFillWarning,
  AiOutlineBarChart,
  AiOutlineBgColors,
  AiOutlineBold,
  AiOutlineCalendar,
  AiOutlineCheck,
  AiOutlineCheckCircle,
  AiOutlineClockCircle,
  AiOutlineClose,
  AiOutlineDash,
  AiOutlineDashboard,
  AiOutlineDotChart,
  AiOutlineDown,
  AiOutlineFieldNumber,
  AiOutlineFontColors,
  AiOutlineInfo,
  AiOutlineInfoCircle,
  AiOutlineItalic,
  AiOutlineLeft,
  AiOutlineLine,
  AiOutlineLineChart,
  AiOutlineMinus,
  AiOutlineOrderedList,
  AiOutlinePlus,
  AiOutlineRight,
  AiOutlineTable,
  AiOutlineUnderline,
  AiOutlineUnorderedList,
  AiOutlineUp,
  AiOutlineWarning,
} from 'react-icons/ai'

export {
  BiBuilding,
  BiBuildings,
  BiCurrentLocation,
  BiError,
  BiErrorAlt,
  BiHelpCircle,
  BiInfoSquare,
  BiLogOut,
  BiLogOutCircle,
  BiMove,
  BiSort,
  BiSortAlt2,
} from 'react-icons/bi'

export {
  BsArrowRight,
  BsCheck,
  BsCircle,
  BsCircleFill,
  BsFile,
  BsFileText,
  BsFillCheckCircleFill,
  BsFillGrid1X2Fill,
  BsImage,
  BsImages,
  BsInputCursor,
  BsInputCursorText,
  BsList,
  BsPlus,
  BsPlusLg,
  BsSortDown,
  BsSortUp,
  BsSortUpAlt,
  BsTable,
  BsX,
  BsCameraVideo,
} from 'react-icons/bs'

export * from 'react-icons/fa'
/* these are the known uses of font awesome icons but we are exporting all font awesome fonts
 so that plugins can dynamically use icons
export {
  FaChevronRight,
  FaCircle,
  FaDatabase,
  FaDrawPolygon,
  FaEllipsisV,
  FaFile,
  FaFileCode,
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaMap,
  FaMapMarked,
  FaMapMarkedAlt,
  FaMapMarker,
  FaMapMarkerAlt,
  FaRegUser,
  FaRegUserCircle,
  FaRoute,
  FaShare,
  FaShareSquare,
  FaSort,
  FaSortNumericDown,
  FaSortNumericDownAlt,
  FaSpinner,
  FaUser,
  FaUsers,
  FaUsersCog,
} from 'react-icons/fa'
 */
export {
  FiArrowLeft,
  FiDivide,
  FiDivideSquare,
  FiDownload,
  FiDownloadCloud,
  FiFilter,
  FiLayers,
  FiX,
} from 'react-icons/fi'

export { GiStairs, GiEarthAmerica } from 'react-icons/gi'
export {
  GoCalendar,
  GoDeviceMobile,
  GoLink,
  GoLinkExternal,
  GoTriangleDown,
} from 'react-icons/go'

export {
  GrTextAlignCenter,
  GrTextAlignFull,
  GrTextAlignLeft,
  GrTextAlignRight,
} from 'react-icons/gr'

export {
  HiOutlineDocument,
  HiOutlineDocumentReport,
  HiOutlineExclamation,
  HiOutlineExclamationCircle,
  HiOutlineOfficeBuilding,
  HiOutlineStatusOnline,
  HiOutlineUser,
  HiOutlineUserCircle,
  HiUser,
  HiUsers,
  HiX,
} from 'react-icons/hi'

export {
  IoCalendarOutline,
  IoChevronDown,
  IoChevronForward,
} from 'react-icons/io5'

export {
  MdAccessTime,
  MdAdd,
  MdAddLocation,
  MdArrowBack,
  MdArrowForward,
  MdBubbleChart,
  MdBuild,
  MdCached,
  MdCalendarToday,
  MdChat,
  MdChatBubble,
  MdChatBubbleOutline,
  MdCheck,
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdCheckCircle,
  MdChevronLeft,
  MdChevronRight,
  MdClear,
  MdClose,
  MdCloud,
  MdCloudUpload,
  MdContentCopy,
  MdDeviceHub,
  MdDone,
  MdEdit,
  MdEmail,
  MdFilter,
  MdFilterAlt,
  MdFormatClear,
  MdFormatListBulleted,
  MdFullscreen,
  MdGroup,
  MdKey,
  MdKeyboard,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
  MdList,
  MdListAlt,
  MdLocationSearching,
  MdLock,
  MdLockOutline,
  MdLoop,
  MdMap,
  MdMode,
  MdModeEdit,
  MdModeEditOutline,
  MdMore,
  MdMoreVert,
  MdOutlineArrowDropDown,
  MdOutlineCamera,
  MdOutlineCameraAlt,
  MdOutlineCheck,
  MdOutlineCheckBox,
  MdOutlineCheckCircle,
  MdOutlineClose,
  MdOutlineContentCopy,
  MdOutlineDone,
  MdOutlineDoneAll,
  MdOutlineDoneOutline,
  MdOutlineDragIndicator,
  MdOutlineError,
  MdOutlineErrorOutline,
  MdOutlineLocationOn,
  MdOutlineQrCode,
  MdOutlineQrCodeScanner,
  MdOutlineRotate90DegreesCcw,
  MdOutlineUndo,
  MdOutlineZoomIn,
  MdOutlineZoomOut,
  MdPageview,
  MdPause,
  MdPauseCircle,
  MdPauseCircleFilled,
  MdPlayArrow,
  MdPlayCircle,
  MdPlayCircleFilled,
  MdPreview,
  MdRedo,
  MdRefresh,
  MdReplay,
  MdRestore,
  MdSave,
  MdScreenShare,
  MdSearch,
  MdSecurity,
  MdStar,
  MdStop,
  MdStopScreenShare,
  MdTextFields,
  MdTimeline,
  MdTimer,
  MdViewCarousel,
  MdViewColumn,
  MdViewList,
  MdViewModule,
  MdViewQuilt,
  MdVisibility,
  MdVisibilityOff,
  MdWarning,
} from 'react-icons/md'

export {
  RiBracesLine,
  RiCalendarTodoLine,
  RiErrorWarningFill,
  RiFileTransferLine,
  RiFlag2Line,
  RiH3,
  RiMenu2Line,
  RiMenu3Line,
} from 'react-icons/ri'

export { SiE, SiE3, SiGo, SiGoogle, SiGoogleclassroom } from 'react-icons/si'

export { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'

export { TbFlag3 } from 'react-icons/tb'

export { VscSettings } from 'react-icons/vsc'

export * from 'svg'
