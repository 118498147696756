/* eslint-disable react/destructuring-assignment */
// libraries
import { ReactElement, forwardRef, useCallback } from 'react'
import Form, { FormProps } from '@rjsf/core'
import validator from '@rjsf/validator-ajv8'
import _ from 'lodash'

// constants
import { CUSTOM_WIDGETS_AND_FIELDS } from 'constants/formBuilder'

// types
import type { JSONFormSchema, JSONFormUiSchema } from 'types/formBuilder'
import type { Payload } from 'types/common'
import type {
  RegistryFieldsType,
  RegistryWidgetsType,
  CustomValidator,
} from '@rjsf/utils'

// utils
import { transformErrors } from 'helpers/formBuilder'
import { useTimezone } from 'hooks'

// components
import CustomTitle from './TitleField'
import CustomSelect from './SelectWidget'
import CustomCheckboxes from './CheckboxesWidget'
import CustomInput from './TextWidget'
import TextareaWidget from './TextareaWidget'
import CustomGeoPoint from './GeoPointWidget'
import CustomFieldTemplate from './FieldTemplate'
import CustomSubmit from './SubmitButton'
import CustomImages from './ImageWidget'
import VideoWidget from './VideoWidget'
import CustomToggle from './ToggleWidget'
import CustomArrayFieldTemplate from './ArrayFieldTemplate'
import CustomArrayFieldItemTemplate from './ArrayFieldItemTemplate'
import CustomDatetime from './DatetimeWidget'
import CustomScanner from './ScannerWidget'
import NumberInputField from './NumberInputField'
import HeadingWidget from './HeadingWidget'
import CustomSignature from './SignatureWidget'
import CustomRadioWidget from './RadioWidget'

import { additionalJsonFormFieldsValidation } from './utils'

const JsonForm = forwardRef(
  (
    {
      uischema,
      schema,
      formData,
      setFormData,
      formContext,
      onBlur,
    }: {
      schema: JSONFormSchema
      uischema?: JSONFormUiSchema
      formData?: Payload
      setFormData?: (v: Payload) => void
      formContext?: Payload
      onBlur: FormProps['onBlur']
    },
    ref
  ): ReactElement => {
    const { timezone } = useTimezone()

    const widgets: RegistryWidgetsType = {
      SelectWidget: CustomSelect,
      TextWidget: CustomInput,
      TextareaWidget,
      SubmitButton: CustomSubmit,
      CheckboxesWidget: CustomCheckboxes,
      CheckboxWidget: CustomToggle,
      DateTimeWidget: CustomDatetime,
      TimeWidget: CustomDatetime,
      DateWidget: CustomDatetime,
      [CUSTOM_WIDGETS_AND_FIELDS.radio]: CustomRadioWidget,
      [CUSTOM_WIDGETS_AND_FIELDS.images]: CustomImages,
      [CUSTOM_WIDGETS_AND_FIELDS.videos]: VideoWidget,
      [CUSTOM_WIDGETS_AND_FIELDS.barcode]: CustomScanner,
      [CUSTOM_WIDGETS_AND_FIELDS.heading]: HeadingWidget,
    }

    const fields: RegistryFieldsType = {
      [CUSTOM_WIDGETS_AND_FIELDS.geopoint]: CustomGeoPoint,
      [CUSTOM_WIDGETS_AND_FIELDS.signature]: CustomSignature,
      NumberField: NumberInputField,
    }

    const templates = {
      DescriptionFieldTemplate: () => null,
      TitleFieldTemplate: CustomTitle,
      FieldTemplate: CustomFieldTemplate,
      ArrayFieldTemplate: CustomArrayFieldTemplate,
      ArrayFieldItemTemplate: CustomArrayFieldItemTemplate,
    }

    const customValidation = useCallback<CustomValidator>(
      (formPayload, errors, uiSchema) =>
        additionalJsonFormFieldsValidation({
          formPayload,
          schema,
          uiSchema,
          errors,
          timezone,
        }),
      [schema, timezone]
    )

    return (
      <Form
        // Use cloneDeep to fix the bug when the form doesn't re-render
        // https://github.com/rjsf-team/react-jsonschema-form/issues/517
        {...(uischema && { uiSchema: _.cloneDeep(uischema) })}
        {...(setFormData && { onChange: e => setFormData(e.formData) })}
        {...(formContext && { formContext })}
        ref={ref}
        schema={schema}
        formData={formData}
        widgets={widgets}
        fields={fields}
        templates={templates}
        transformErrors={transformErrors}
        showErrorList={false}
        validator={validator}
        onBlur={onBlur}
        customValidate={customValidation}
        noHtml5Validate
      />
    )
  }
)

export default JsonForm
