import { useState, useCallback, useMemo } from 'react'
import { useUpdateEffect } from 'react-use'
import _ from 'lodash'

// constants
import { APP_NAMES } from 'constants/common'

// utils
import AuthService from 'services/authentication'
import { defineRulesFor } from 'helpers/ability'
import { isSuperAdmin, isGroupAdmin } from 'helpers/user'
import { useStateValue, useAbilityStateValue } from 'contexts'
import { setLogUser } from 'helpers/log'
import { useConfigStateValue } from 'contexts/ConfigContext'
import { setUserPropertiesForAnalytics } from 'helpers/firebase'

import type { User } from 'types/user'

const useAuthCurrentUser = (): {
  appName: keyof typeof APP_NAMES
  currentUser: User
  setCurrentUser: React.Dispatch<React.SetStateAction<User>>
  currentUserRoles: { isSuperAdminRole: boolean; isGroupAdminRole: boolean }
} => {
  const {
    actions: {
      userActions: { updateUser: updateContextUser },
    },
  } = useStateValue()
  const { ability } = useAbilityStateValue()
  const [currentUser, setCurrentUser] = useState<User>({} as User)
  const { caslRules, role } = currentUser || {}

  useUpdateEffect(() => {
    const rules = _.isEmpty(caslRules) ? defineRulesFor(currentUser) : caslRules
    ability.update(rules)
  }, [caslRules])

  const currentUserRoles = useMemo(() => {
    return {
      isSuperAdminRole: isSuperAdmin(role),
      isGroupAdminRole: isGroupAdmin(role),
    }
  }, [role])

  const setCurrentUserWithCheck = useCallback((newCurrentUser: User) => {
    if (newCurrentUser) {
      setCurrentUser(newCurrentUser)
    }
  }, [])

  useUpdateEffect(() => {
    if (_.isEmpty(currentUser)) return

    AuthService.currentUser = currentUser
    setLogUser(currentUser)

    updateContextUser(currentUser)
  }, [currentUser])

  const { logging } = useConfigStateValue()
  useUpdateEffect(() => {
    setUserPropertiesForAnalytics(currentUser, logging.environment)
  }, [currentUser, logging])

  const appName = useMemo(
    () =>
      _.get(
        APP_NAMES,
        process.env.REACT_APP_SU_EXPLORER_APPLICATION as string
      ) || APP_NAMES.studio,
    []
  )

  return {
    appName,
    currentUser,
    currentUserRoles,
    setCurrentUser: setCurrentUserWithCheck,
  }
}

export default useAuthCurrentUser
