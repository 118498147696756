import _ from 'lodash'

import type { JsonFormBody } from 'types/graphql'

/** Searches for a tag within UISchema and returns the name of the first field found with that tag */
export const getFieldNameByTag = ({
  jsonFormBody,
  tagName,
}: {
  jsonFormBody?: JsonFormBody
  tagName: string
}) => {
  if (!jsonFormBody) return undefined

  const { uischema } = jsonFormBody
  const fieldsOrder = (uischema['ui:order'] || []) as string[]
  const commentFieldName = fieldsOrder.find(fieldName =>
    _(uischema).get([fieldName, 'ui:options', 'tags'], []).includes(tagName)
  )

  return commentFieldName
}

/** Searches for a field name by tag and then returns a value of this field */
export const getFieldValueByTag = ({
  dataCollectionResponses,
  tagName,
  jsonFormBody,
}: {
  dataCollectionResponses: Record<string, unknown>
  tagName: string
  jsonFormBody?: JsonFormBody
}) => {
  const commentFieldName = getFieldNameByTag({ jsonFormBody, tagName })

  return commentFieldName
    ? (dataCollectionResponses?.[commentFieldName] as string)
    : undefined
}
