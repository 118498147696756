import { selector, selectorFamily } from 'recoil'
import _ from 'lodash'
import { feature as turfFeature } from '@turf/helpers'

import { validateGeojson } from 'helpers/geojson'
import { SuGraphqlError } from 'helpers/graphql'
import { getAssetSites, getAssetWells } from '../services/api/assetType'

export const assetSitesListQuery = selectorFamily({
  key: 'assetSitesListQuery',
  get: () => async () => {
    const { data, error } = await getAssetSites({
      properties: ['FLOC', 'name', 'isDAC', 'id'],
      assetsOmitFields: [],
    })

    if (error) {
      if (_.isEmpty(data)) {
        throw new SuGraphqlError({
          error: `Failed to fetch asset sites: ${error}`,
        })
      }
    }
    return data
  },
})

export const assetSitesOptionsState = selector({
  key: 'assetSitesOptionsState',
  get: ({ get }) => {
    const list = get(assetSitesListQuery({}))
    return _.map(list, ({ properties, assetReference, geometryJson }) => {
      const siteId = properties?.FLOC
      const label = properties?.name
      const observation = turfFeature(geometryJson)
      return {
        properties,
        label,
        siteId,
        labelExtras: {
          description: siteId || label,
        },
        value: assetReference,
        ...(validateGeojson(observation) && {
          observation,
        }),
      }
    })
  },
})

export const assetWellsListQuery = selectorFamily({
  key: 'assetWellsListQuery',
  get: () => async () => {
    const { data, error } = await getAssetWells({
      properties: ['name'],
      assetsOmitFields: [],
    })

    if (error) {
      if (_.isEmpty(data)) {
        throw new SuGraphqlError({
          error: `Failed to fetch asset wells: ${error}`,
        })
      }
    }

    return data
  },
})

export const assetWellAndSiteListState = selector({
  key: 'assetWellAndSiteListState',
  get: ({ get }) => {
    const sites = get(assetSitesListQuery({}))
    const wells = get(assetWellsListQuery({}))

    return _.keyBy([...sites, ...wells], 'assetReference')
  },
})
