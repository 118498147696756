// libraries
import _ from 'lodash'
import { createSelector } from 'reselect'

// constants
import { DATASET_TYPES } from 'constants/unipipe'
import { MESSAGE_TYPES } from 'constants/message'

// utils
import {
  getMapEligibleDatasets,
  getMapEligibleLiveDatasets,
  getWorkflowEligibleDatasets,
  getDatasetsByTimeliness,
  getDatasetOption,
  getWorkflowEligibleLiveDataset,
} from 'helpers/unipipe'

import type { DatasetId, UpSpecification } from 'types/unipipe'
import type {
  DatasetMetadata,
  Options,
  DatasetOptions,
  DatasetIdentifier,
} from 'types/common'
import type { States, UnipipeState } from 'types/states'

const getUnipipeState = (state: States): UnipipeState => state.unipipeState

const getCatalog = createSelector(getUnipipeState, ({ catalog }) => catalog)

const getAvailableAssetProfiles = createSelector(
  getUnipipeState,
  ({ catalog }) =>
    _.uniq(Object.keys(catalog).map(key => catalog[key].assetProfile))
)

const getToasts = createSelector(getUnipipeState, ({ toasts }) => toasts)

const getLatestToast = createSelector(
  getUnipipeState,
  ({ latestToast }) => latestToast
)

const getToastsByType = type => toasts => _.filter(toasts, { type })

const getSuccessToasts = createSelector(
  getToasts,
  getToastsByType(MESSAGE_TYPES.success)
)

const getInfoToasts = createSelector(
  getToasts,
  getToastsByType(MESSAGE_TYPES.info)
)

const getErrorToasts = createSelector(
  getToasts,
  getToastsByType(MESSAGE_TYPES.error)
)

const getOptions = getOption => catalog =>
  _(catalog)
    .map(getOption)
    .sortBy(({ label }) => _.upperFirst(label))
    .value()

const getDatasetOptions = createSelector(
  getCatalog,
  getOptions(getDatasetOption)
)

const getDatasetGroupOptions = createSelector(
  getUnipipeState,
  ({ groups }) => groups || []
)

const getMapEligibleDatasetOptions = createSelector(
  getDatasetOptions,
  getMapEligibleDatasets
)

const getMapEligibleLiveDatasetOptions = createSelector(
  getMapEligibleDatasetOptions,
  getMapEligibleLiveDatasets
)

const getMapEligibleHistoricalDatasetOptions = createSelector(
  getMapEligibleDatasetOptions,
  getDatasetsByTimeliness(DATASET_TYPES.historical)
)

const getMapEligibleFeatureDatasetOptions = createSelector(
  getMapEligibleDatasetOptions,
  getDatasetsByTimeliness(DATASET_TYPES.feature)
)

const getWorkflowEligibleDatasetOptions = createSelector(
  getDatasetOptions,
  getWorkflowEligibleDatasets
)

const getWorkflowEligibleLiveDatasetOptions = createSelector(
  getDatasetOptions,
  getWorkflowEligibleLiveDataset
)

const getFeatureDatasetOptions = createSelector(
  getDatasetOptions,
  getDatasetsByTimeliness(DATASET_TYPES.feature)
)

export type PickedDatasetMetadata = Pick<
  DatasetMetadata,
  | 'value'
  | 'label'
  | 'dataset'
  | 'identityProperty'
  | 'assetProfile'
  | 'hints'
  | 'properties'
  | 'source'
  | 'timeliness'
  | 'specificationParameters'
>
export type PickedDatasetsMetadata = Record<DatasetId, PickedDatasetMetadata>

const getKeyByDatasetMetadata = createSelector(getDatasetOptions, options => {
  return _(options)
    .map(option =>
      _.pick(option, [
        'value',
        'label',
        'dataset',
        'identityProperty',
        'assetProfile',
        'hints',
        'properties',
        'source',
        'specificationParameters',
      ])
    )
    .keyBy('dataset')
    .value() as PickedDatasetsMetadata
})

const unipipeSelectors = (
  state: States
): {
  catalog: Record<DatasetIdentifier, UpSpecification>
  availableAssetProfiles: string[]
  datasetGroupOptions: Options
  datasetOptions: DatasetOptions
  pickedDatasetsMetadata: PickedDatasetsMetadata
  mapEligibleDatasetOptions: DatasetOptions
  mapEligibleLiveDatasetOptions: DatasetOptions
  mapEligibleHistoricalDatasetOptions: DatasetOptions
  mapEligibleFeatureDatasetOptions: DatasetOptions
  workflowEligibleDatasetOptions: DatasetOptions
  workflowEligibleLiveDatasetOptions: DatasetOptions
  featureDatasetOptions: DatasetOptions
  toasts: string[]
  latestToast: string
  successToasts: string[]
  infoToasts: string[]
  errorToasts: string[]
} => ({
  catalog: getCatalog(state),
  datasetGroupOptions: getDatasetGroupOptions(state),
  availableAssetProfiles: getAvailableAssetProfiles(state),
  datasetOptions: getDatasetOptions(state),
  pickedDatasetsMetadata: getKeyByDatasetMetadata(state),
  mapEligibleDatasetOptions: getMapEligibleDatasetOptions(state),
  mapEligibleLiveDatasetOptions: getMapEligibleLiveDatasetOptions(state),
  mapEligibleHistoricalDatasetOptions:
    getMapEligibleHistoricalDatasetOptions(state),
  mapEligibleFeatureDatasetOptions: getMapEligibleFeatureDatasetOptions(state),
  workflowEligibleDatasetOptions: getWorkflowEligibleDatasetOptions(state),
  workflowEligibleLiveDatasetOptions:
    getWorkflowEligibleLiveDatasetOptions(state),
  featureDatasetOptions: getFeatureDatasetOptions(state),
  toasts: getToasts(state),
  latestToast: getLatestToast(state),
  successToasts: getSuccessToasts(state),
  infoToasts: getInfoToasts(state),
  errorToasts: getErrorToasts(state),
})

export default unipipeSelectors
