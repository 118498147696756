import _ from 'lodash'
import { useMemo } from 'react'

// constants
import { DETECTION_LIST_FILTER_TYPES } from 'app/MissionControlMethaneSolution/constants/detection'
import { FEATURES } from 'constants/settings'

// utils
import { useFeatureFlag } from 'hooks'

import type { FilterSpec, Filters } from 'types/filter'

const useDetectionCustomFilters = ({
  customFiltersSpecs,
  customizedFiltersKeys,
  customizedDefaultFilters,
}: {
  customFiltersSpecs?: FilterSpec[]
  customizedFiltersKeys?: string[]
  customizedDefaultFilters?: Filters
}) => {
  const { [FEATURES.GEMS_CONFIGURATION]: gemsConfiguration } = useFeatureFlag()

  const { enableDACSitesFilter } = gemsConfiguration ?? {}

  const rejectFilterSpecKeys = useMemo(
    () =>
      _.compact([
        enableDACSitesFilter ? '' : DETECTION_LIST_FILTER_TYPES.dacSite,
      ]),
    [enableDACSitesFilter]
  )

  const shouldRejectFilters = !_.isEmpty(rejectFilterSpecKeys)

  return {
    customFiltersSpecs: useMemo(
      () =>
        shouldRejectFilters
          ? _.reject(customFiltersSpecs, ({ key }) =>
              _.includes(rejectFilterSpecKeys, key)
            )
          : customFiltersSpecs,
      [customFiltersSpecs, rejectFilterSpecKeys, shouldRejectFilters]
    ),
    customizedFiltersKeys: useMemo(
      () =>
        shouldRejectFilters
          ? _.reject(customizedFiltersKeys, key =>
              _.includes(rejectFilterSpecKeys, key)
            )
          : customizedFiltersKeys,
      [customizedFiltersKeys, rejectFilterSpecKeys, shouldRejectFilters]
    ),
    customizedDefaultFilters: useMemo(
      () =>
        shouldRejectFilters
          ? _.omit(customizedDefaultFilters, rejectFilterSpecKeys)
          : customizedDefaultFilters,
      [customizedDefaultFilters, rejectFilterSpecKeys, shouldRejectFilters]
    ),
  }
}

export default useDetectionCustomFilters
