// libraries
import _ from 'lodash'
import moment from 'moment-timezone'
import geojsonRbush from 'geojson-rbush'
import crossfilter from 'crossfilter2'

// constants
import {
  PROPERTY_VARIABLE_TYPES,
  OPERATORS,
  FILTER_CONDITIONS,
  PROPERTY_VARIABLE_FORMATS,
  DATE_RANGE_FILTER_VALUES,
  DATE_RANGE_FILTER_POSSIBLE_VALUES,
} from 'constants/filter'
import { NAME_PROPERTY_KEY } from 'constants/unipipe'
import { PAGE_FILTER_PLACEHOLDER, PROPERTY_TIME } from 'constants/common'
import { DATE_FORMAT, DATE_UNIT_TYPES } from 'constants/datetime'

// utils
import {
  toLowerCase,
  isValueInRange,
  sortKeysByDate,
  stripBom,
  displayTime,
} from 'helpers/utils'
import { getLayerSelectedDatetimeRange } from 'helpers/map'
import {
  getSnappedDateMoment,
  isValidISODatetimeString,
} from 'helpers/datetime'

import type { Option, Value } from 'types/common'
import { Timezone, UtcISOString } from 'types/datetime'

export const generateSpatialIndexedTree = data => {
  if (_.isEmpty(data)) {
    return undefined
  }

  // rtree indexing
  // bulk load features bboxes into rtree (rbush.load)
  const tree = geojsonRbush()
  tree.load(data)
  return tree
}

export const getBoundingBoxFilteredData = tree => bbox => {
  if (!tree || !bbox) {
    return []
  }

  const result = tree.search(bbox)
  const features = result?.features || []
  return features.sort((a, b) => {
    return sortKeysByDate(a.properties.time, b.properties.time)
  })
}

export const getSpatialFilterFn = data => {
  const filteredDataTree = generateSpatialIndexedTree(data)
  return getBoundingBoxFilteredData(filteredDataTree)
}

export const getCrossfilterDimension = ({
  data,
  cf,
  propertyPath,
  defaultValue = '',
}) => {
  if (!propertyPath || (!cf && !data)) return undefined

  const cfInstance = cf || crossfilter(data)
  return cfInstance.dimension(d => _.get(d, propertyPath, defaultValue))
}

export const getCrossfilterTimeDimension = ({
  data,
  cf,
  propertyPath = PROPERTY_TIME,
}) => {
  if (!propertyPath || (!cf && !data)) return undefined

  const cfInstance = cf || crossfilter(data)
  return cfInstance.dimension(d => Date.parse(_.get(d, propertyPath)))
}

export const getCrossfilterCategoryDimension = data => {
  return getCrossfilterDimension({ data, propertyPath: 'category' })
}

export const getColourFromClassificationByCategoryValue = (
  value,
  crossFilterDimension
) => {
  if (!crossFilterDimension) return undefined
  crossFilterDimension.filterFunction(
    d => toLowerCase(d) === toLowerCase(value)
  )
  return _.first(crossFilterDimension.top(1))
}

export const getTimeFilteredData = ({ datetimeDim, start, end }) => {
  if (!datetimeDim) return []
  // results includes data with the end time
  datetimeDim.filterRange([Date.parse(start), Date.parse(end) + 1])
  // * The returned array is sorted by descending order.
  // https://github.com/crossfilter/crossfilter/wiki/API-Reference#dimension_top
  return datetimeDim.top(Infinity)
}

export const getLayerTimeFilteredData = ({
  layer,
  datetimeDim,
  selectedDateTimeRange,
}) => {
  const { start, end } = getLayerSelectedDatetimeRange(
    layer,
    selectedDateTimeRange
  )

  return getTimeFilteredData({
    datetimeDim,
    start,
    end,
  })
}

/**
 * Get the time filtered layer data
 * If the useDeckglTimeFilter is true, which means the historical layer data is
 * only filtered by the layer dateTimeRange but not the global selected
 * dateTimeRange. It needs to apply the valid time filter, which is based on
 * the overlap between the layer dateTimeRange and the global selected
 * dateTimeRange, to the historical layer data to get the data for the table.
 * @prop {Object} layer - the layer object to get the data { id, timeliness, specParams, filterCondition }
 * @prop {Object} layersFilteredData - layers filtered data
 * @prop {Object} selectedDateTimeRange - the global selected DateTime range
 *
 * @return {Array} - time filtered data
 */
export const getLayerFilteredData = ({
  layer,
  bbox,
  layersFilteredData,
  isSpatialFilterEnabled = true,
  pickFields,
}) => {
  const { id: layerId } = layer

  const { applySpatialFilter, filteredData } =
    _.get(layersFilteredData, layerId) || {}

  const shouldApplySpatialFilter =
    isSpatialFilterEnabled &&
    _.isFunction(applySpatialFilter) &&
    !_.isEmpty(bbox)

  const geojsonData = shouldApplySpatialFilter
    ? applySpatialFilter(bbox)
    : filteredData

  return _.isEmpty(geojsonData)
    ? []
    : pickFields
    ? geojsonData.map(data => {
        return _.pick(data, pickFields)
      })
    : geojsonData
}

export const isPropertyName = value => value === NAME_PROPERTY_KEY

export const isPropertyTimeFormat = format =>
  format === PROPERTY_VARIABLE_FORMATS.time

export const isTimePropertyOption = ({ format } = {}) =>
  isPropertyTimeFormat(format)

export const isTimePropertyFromPropertyOptionAndValue = (
  value,
  propertyOptions
) => {
  const findSelectedProperty = _.find(propertyOptions, {
    value,
  })
  return isTimePropertyOption(findSelectedProperty)
}

export const getNonDatetimeProperties = properties =>
  _.reject(properties, isTimePropertyOption)

export const findProperty = (options, value) =>
  _.find(options, ['value', value]) || {}

export const isNumericType = (type: string) =>
  type === PROPERTY_VARIABLE_TYPES.number

export const validNumericProperties = propertyOptions =>
  _.filter(propertyOptions, ['type', PROPERTY_VARIABLE_TYPES.number])

// A '[' indicates inclusion of a value. A '(' indicates exclusion.
export const isTimeBetween = (
  time: string,
  start: string,
  end?: string,
  granularity?: string
) => {
  if (!start && end) {
    return moment.utc(time).isSameOrBefore(moment.utc(end), granularity)
  }

  if (start && !end) {
    return moment.utc(time).isSameOrAfter(moment.utc(start), granularity)
  }

  if (start && end) {
    return moment
      .utc(time)
      .isBetween(moment.utc(start), moment.utc(end), granularity, '[]')
  }

  return true
}

const timeFilterHandlers = [
  [
    OPERATORS.isTimeBetween,
    (val, { start, end }) => isTimeBetween(val, start, end),
  ],
  [
    OPERATORS.isTimeEquals,
    (val, targetVal) => moment.utc(val).isSame(moment.utc(targetVal)),
  ],
  [
    OPERATORS.isAfter,
    (val, targetVal) => moment.utc(val).isAfter(moment.utc(targetVal)),
  ],
  [
    OPERATORS.isSameOrAfter,
    (val, targetVal) => moment.utc(val).isSameOrAfter(moment.utc(targetVal)),
  ],
  [
    OPERATORS.isBefore,
    (val, targetVal) => moment.utc(val).isBefore(moment.utc(targetVal)),
  ],
  [
    OPERATORS.isSameOrBefore,
    (val, targetVal) => moment.utc(val).isSameOrBefore(moment.utc(targetVal)),
  ],
]

const numericalFilterHandlers = [
  [OPERATORS.isBetween, (val, range) => isValueInRange(val, range)],
  [OPERATORS.isGreaterThan, (val, targetVal) => val > targetVal],
  [OPERATORS.isLessThan, (val, targetVal) => val < targetVal],
  [OPERATORS.isGreaterThanOrEquals, (val, targetVal) => val >= targetVal],
  [OPERATORS.isLessThanOrEquals, (val, targetVal) => val <= targetVal],
]

const toLowercaseString = str => (`${str}` ? `${str}`.toLowerCase() : '')

const isStringContainsCharacters = (value, targetVal) =>
  toLowercaseString(value).includes(toLowercaseString(targetVal))

const isStringNotContainsCharacters = (values, targetVal) =>
  !isStringContainsCharacters(values, targetVal)

const stringFilterHandlers = [
  [OPERATORS.contains, isStringContainsCharacters],
  [OPERATORS.doesNotContain, isStringNotContainsCharacters],
  [OPERATORS.isSet, val => val],
]

const booleanFilterHandlers = [
  [OPERATORS.isTrue, val => !!val],
  [OPERATORS.isFalse, val => !val],
]

const isValueEqual = (val, targetVal) =>
  toLowercaseString(val) === toLowercaseString(targetVal)

const isValueNotEqual = (val, targetVal) => !isValueEqual(val, targetVal)

const sharedFilterHandlers = [
  [OPERATORS.equals, isValueEqual],
  [OPERATORS.doesNotEqual, isValueNotEqual],
]

const filterHandlers = new Map([
  ...timeFilterHandlers,
  ...numericalFilterHandlers,
  ...stringFilterHandlers,
  ...booleanFilterHandlers,
  ...sharedFilterHandlers,
  [],
])

const getPropertyValueByPath = path => (d, property) => {
  const propertyPath = _.compact([path, property]).join('.')
  return _.get(d, propertyPath)
}
/**
 * Apply filters against the given data
 * @param {Array} data - The array data to iterate over.
 * @param {Array} filters - The filters to apply against the data.
 * @param {String} propertyPathPrefix - The property path prefix to access
 * @param {String}[filterCondition=FILTER_CONDITIONS.and] - The relationship between each filters. In other words, the data needs to meet every filters or any one of filters
 *
 * @return {Array} filtered data
 */
export const applyFilters = (
  data,
  filters,
  propertyPathPrefix = 'properties',
  filterCondition = FILTER_CONDITIONS.and
) => {
  if (_.isEmpty(data) || _.isEmpty(filters)) return data

  const predicate = filterCondition === FILTER_CONDITIONS.or ? 'some' : 'every'

  const getPropertyValue = getPropertyValueByPath(propertyPathPrefix)

  return data.filter(d =>
    _[predicate](filters, filter => {
      const { property, operator, value } = filter

      const propertyValue = stripBom(getPropertyValue(d, property))

      if (_.isNil(propertyValue)) return false

      if (!operator) return true

      // This handles the special case for NGFR
      // https://sensorup.atlassian.net/browse/DHS-21?focusedCommentId=13378&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-13378
      // Filter the data that reached the expiryTime
      if (property === 'expiryTime') {
        return operator === OPERATORS.isTrue
          ? moment(propertyValue).isAfter()
          : true
      }

      const handler = filterHandlers.get(operator)
      // return true for a non existing handler or an invalid filter value
      // otherwise, use the specified filter to handle the check
      return handler ? handler(propertyValue, value) : true
    })
  )
}

/**
 * Finds all options that have a value matching any of the values in the given array.
 * The value will be an array of strings, e.g., ['val1', 'val2'].
 * The function handles both flat and grouped options structures.
 */
export const findOptionsByArrays = (
  options: Option[],
  optionKey: string,
  paths: string[]
) => {
  const flattenedGroupedOptions = _.flatMap(options, opt => opt.options ?? opt)
  return _(flattenedGroupedOptions).keyBy(optionKey).at(paths).compact().value()
}
/**
 * find the option which has the given value
 * the value can be a array, number, string or an object,
 */
export const findOptionByValue = (
  options: Option[],
  value: Value,
  optionKey: string,
  preSelect?: boolean
) => {
  const result = _.find(options, [optionKey, value])
  return preSelect && !result && options?.length === 1
    ? _.first(options)
    : result
}

/**
 * find the option which has the given value from a specific group
 */
export const findOptionFromGroupedOptionsByValue = ({
  options,
  value,
  group,
  optionKey = 'value',
  optionLabel = 'label',
}: {
  options: Option[]
  value: Value
  group: string
  optionKey?: string
  optionLabel?: string
}) => {
  const groupedOptions = _.find(options, [optionLabel, group])
  return _.find(_.get(groupedOptions, 'options'), [optionKey, value])
}
export const findOptionFromGroupedOptionsByValueTwo = ({
  options,
  value,
  optionKey = 'value',
}: {
  options: Option[]
  value: Value
  optionKey?: string
}) => {
  return _.find(
    _.flatMap(options, group => _.get(group, 'options')),
    { [optionKey]: value }
  )
}

export const getCommonFilterSpecs = (key: string, otherSpecs = {}) => {
  return {
    key,
    label: key,
    ...otherSpecs,
  }
}

export const getCrossfilterDimensions = (data, filtersSpecs) => {
  const cf = crossfilter(data)
  return _.reduce(
    filtersSpecs,
    (acc, spec, key) => {
      const { propertyPath } = spec
      const cfDimension = getCrossfilterDimension({
        cf,
        propertyPath,
      })
      return {
        ...acc,
        [key]: cfDimension,
      }
    },
    { cf }
  )
}

export const defaultFilterFn = (val: Value) => (d: unknown) =>
  _.isNil(d) || _.isEmpty(d) ? false : d === val

export const getFilteredDataWithMultiDimensions = ({
  data = [],
  filterValues,
  filtersSpecs,
}) => {
  const crossfilterDimensions = getCrossfilterDimensions(data, filtersSpecs)

  _.forEach(filterValues, (valueArray, key) => {
    const issueDimension = crossfilterDimensions[key]
    if (_.isEmpty(valueArray) || !issueDimension) return

    const { filterFn, isMulti = true } = filtersSpecs[key]
    const value = isMulti ? valueArray : _.first(valueArray)
    if (isMulti ? _.isEmpty(value) : _.isNil(value)) return

    const filterAccessor =
      filterFn || (isMulti ? list => v => _.includes(list, v) : undefined)
    issueDimension.filter(filterAccessor ? filterAccessor(value) : value)
  })

  return crossfilterDimensions.cf.allFiltered()
}

const getDisplayTime = (datetime: UtcISOString, timezone: Timezone) =>
  datetime
    ? displayTime({
        datetime,
        timezone,
        timeFormat: DATE_FORMAT,
      })
    : PAGE_FILTER_PLACEHOLDER

export const getDateFilterLabel = ({
  startDate,
  endDate,
  timezone,
  options,
}) => {
  const isTimeRelative = !isValidISODatetimeString(startDate)

  const start = isTimeRelative
    ? _.find(options, { value: startDate })?.label
    : getDisplayTime(startDate, timezone)
  const end = isTimeRelative ? '' : ` – ${getDisplayTime(endDate, timezone)}`

  return `${start || ''}${end}`
}

const FILTER_VALUE_MAPPERS = {
  [DATE_RANGE_FILTER_VALUES.today]: (timezone: Timezone) =>
    getSnappedDateMoment({
      timezone,
      unit: DATE_UNIT_TYPES.days,
    }).toISOString(),
  [DATE_RANGE_FILTER_VALUES.last_seven_days]: (timezone: Timezone) => {
    const weekAgo = moment.utc().subtract(7, DATE_UNIT_TYPES.days)
    return getSnappedDateMoment({
      baseDatetime: weekAgo,
      timezone,
      unit: DATE_UNIT_TYPES.days,
    }).toISOString()
  },
  [DATE_RANGE_FILTER_VALUES.this_month]: (timezone: Timezone) =>
    getSnappedDateMoment({
      timezone,
      unit: DATE_UNIT_TYPES.months,
    }).toISOString(),
  [DATE_RANGE_FILTER_VALUES.this_quarter]: (timezone: Timezone) =>
    getSnappedDateMoment({
      timezone,
      unit: DATE_UNIT_TYPES.quarters,
    }).toISOString(),
  [DATE_RANGE_FILTER_VALUES.this_year]: (timezone: Timezone) =>
    getSnappedDateMoment({
      timezone,
      unit: DATE_UNIT_TYPES.years,
    }).toISOString(),
  [DATE_RANGE_FILTER_VALUES.last_year_current_year]: (timezone: Timezone) =>
    getSnappedDateMoment({
      baseDatetime: moment.utc().subtract(1, DATE_UNIT_TYPES.years),
      timezone,
      unit: DATE_UNIT_TYPES.years,
    }).toISOString(),
  [DATE_RANGE_FILTER_VALUES.all]: (timezone: Timezone) =>
    getSnappedDateMoment({
      baseDatetime: moment.utc().subtract(4, DATE_UNIT_TYPES.years),
      timezone,
      unit: DATE_UNIT_TYPES.years,
    }).toISOString(),
}

export const isValidDateFilterValue = dateFilterValue =>
  dateFilterValue &&
  (DATE_RANGE_FILTER_POSSIBLE_VALUES.includes(dateFilterValue) ||
    isValidISODatetimeString(dateFilterValue))

/** Transforms 'today', 'this month', etc to a real date  */
export const transformDateFilterValue = (
  dateFilterValue,
  { timezone, customFilterValueMappers }
) => {
  if (!isValidDateFilterValue(dateFilterValue)) return undefined

  if (DATE_RANGE_FILTER_POSSIBLE_VALUES.includes(dateFilterValue)) {
    return { ...FILTER_VALUE_MAPPERS, ...customFilterValueMappers }[
      dateFilterValue
    ](timezone)
  }

  return dateFilterValue
}
