import keymirror from 'keymirror'
import { scaleLinear } from 'd3'
import _ from 'lodash'

import type { DetectionType } from 'app/MissionControlMethaneSolution/types/detection'
import { DETECTION_TYPES } from './common'

export const EMISSION_OBSERVATION_TYPE_ICONS_MAPPING = {
  [DETECTION_TYPES.DETECTION_TYPE_CMS]: 'MethaneCMSIcon',
  [DETECTION_TYPES.DETECTION_TYPE_DRONE]: 'MethaneDroneIcon',
  [DETECTION_TYPES.DETECTION_TYPE_FLYOVER]: 'MethaneFlyOverIcon',
  [DETECTION_TYPES.DETECTION_TYPE_SATELLITE]: 'MethaneSatelliteIcon',
  [DETECTION_TYPES.DETECTION_TYPE_OGI]: 'MethaneOGIIcon',
  [DETECTION_TYPES.DETECTION_TYPE_OWD]: 'FaWalking',
  [DETECTION_TYPES.DETECTION_TYPE_MANUAL]: 'MethaneManualIcon',
  [DETECTION_TYPES.DETECTION_TYPE_VENTING_FLARING_BLOWDOWN]: 'MethaneVFBIcon',
  [DETECTION_TYPES.DETECTION_TYPE_UNKNOWN]: 'MethaneUnknownIcon',
}

export const EMISSION_OBSERVATION_TYPE_TOOLTIPS = {
  [DETECTION_TYPES.DETECTION_TYPE_CMS]: 'Continuous Monitoring System',
  [DETECTION_TYPES.DETECTION_TYPE_DRONE]: 'Drone',
  [DETECTION_TYPES.DETECTION_TYPE_OGI]: 'Optical Gas Imaging',
  [DETECTION_TYPES.DETECTION_TYPE_OWD]: 'On-site Walk Down',
  [DETECTION_TYPES.DETECTION_TYPE_FLYOVER]: 'Flyover',
  [DETECTION_TYPES.DETECTION_TYPE_SATELLITE]: 'Satellite',
  [DETECTION_TYPES.DETECTION_TYPE_MANUAL]: 'Manual',
  [DETECTION_TYPES.DETECTION_TYPE_UNKNOWN]: 'Unknown',
  [DETECTION_TYPES.DETECTION_TYPE_VENTING_FLARING_BLOWDOWN]: 'VFB',
}

export const EMISSION_OBSERVATION_ATTRIBUTION_TYPES = keymirror({
  SITE: null,
  EQUIPMENT: null,
})

export const DETECTION_TYPES_TO_OMIT_ON_MAP: DetectionType[] = [
  DETECTION_TYPES.DETECTION_TYPE_OGI,
]

export const pointSizeRange = [8, 45]

export const getScaledPointRadius = (
  d,
  property = 'properties.emissionsRate'
) => scaleLinear().domain([0, 80000]).range(pointSizeRange)(_.get(d, property))
